/* page header starts */
.page_header_container {
  background-color: #0b163f;
  padding: 60px 0;
  overflow: hidden;
}

.page_header_container {
  background-image: url("../../img/blur-banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: center;
}

.page_header_container .page_header {
  width: 90%;
  margin: 0 auto;
  position: relative;
}

.page_header_container .page_header .main_heading {
  width: 70%;
}

.page_header_container .page_header .main_heading h1 {
  font-size: 3.1rem;
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
}

.page_header_container .page_header .main_heading p.lead {
  font-size: 1.6rem;
  color: #d6d5e1;

  text-align: justify;
  font-family: "Poppins", sans-serif;
}

/* top or first feature section */
.market_feature_section {
  background-color: #fffbfb;
}

.market_feature_section .market_feature_wrapper {
  width: 90%;
  margin: 0 auto;
}

.market_feature_section .market_feature_wrapper .market_feature_content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.market_feature_section .market_feature_wrapper .market_feature_content .left {
  display: flex;
  background-image: url(../../img/shape/dot-big-square.png);
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;

  background-position: 51px 188px;
}

.market_feature_section
  .market_feature_wrapper
  .market_feature_content
  .left
  img {
  width: 70%;
}

.market_feature_section .market_feature_wrapper .market_feature_content .right {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.market_feature_section
  .market_feature_wrapper
  .market_feature_content
  .right
  p {
  font-size: 1.6rem;
  color: #575757;
  font-family: "Poppins", sans-serif;
  text-align: justify;
}

.market_feature_section
  .market_feature_wrapper
  .market_feature_content
  .right
  ol
  li {
  list-style-position: outside;
  font-size: 1.6rem;
  margin: 10px 0;
  color: #575757;
  text-align: justify;
  font-family: "Poppins", sans-serif;
}

.our_services {
  font-size: 1.5rem;
  display: inline-block;
  font-family: "Poppins", sans-serif;
  /* margin-left: -18px; */
  margin-top: 10px;
  color: #575757;
}

.market_feature_section .market_feature_wrapper .market_feature_heading {
  margin-bottom: 20px;
}

.market_feature_section .market_feature_wrapper h2 {
  font-size: 2.6rem;
  font-family: "Roboto", sans-serif;
  color: #071c4d;
  font-weight: 900;
  text-align: center;
  /* margin-left: -18px; */
}

/* our market features sectin starts here */
.market_features {
  background-color: #fffbfb;
  padding: 40px 0;
}

.market_features .market_feature_wrapper {
  width: 90%;
  margin: 0 auto;
}

.market_features .market_feature_wrapper .market_feature_heading {
  text-align: center;
  margin-bottom: 30px;
}

.market_features .market_feature_wrapper .market_feature_heading .sub_heading {
  font-size: 2rem;
}

.market_features .market_feature_wrapper .market_feature_heading h3 {
  font-size: 3rem;
  color: #071c4d;
  font-family: "Roboto", sans-serif;
}

.market_features .market_feature_wrapper .market_feature_heading p {
  font-size: 1.6rem;

  color: #575757;
}

.market_features .market_feature_wrapper .market_feature_cards {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.market_features .market_feature_wrapper .market_feature_cards .market_card {
  display: flex;
  padding: 40px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #0b163f;
  border: 1.2px solid #57575721;
  transition: 0.5s all;
  border-radius: 5px;
  border-radius: 10px;
}

.market_features
  .market_feature_wrapper
  .market_feature_cards
  .market_card:hover {
  transform: translateY(-5px);

  box-shadow: 10px 10px 20px #c7c7c7, -10px -10px 20px #f9f9f9;
}

.market_features
  .market_feature_wrapper
  .market_feature_cards
  .market_card
  .img {
  padding: 10px;
  background-color: #e9effc;
}

.market_features
  .market_feature_wrapper
  .market_feature_cards
  .market_card
  img {
  width: 40px;
}

.market_features .market_feature_wrapper .market_feature_cards .market_card h3 {
  font-size: 1.8rem;
  margin: 12px 0;
  font-weight: 500;
  /* font-family:  'Poppins', sans-serif; */
  font-family: "Roboto", sans-serif;
  /* font-family: "Open Sans", sans-serif; */
  text-align: center;
  color: white;
}

.market_features .market_feature_wrapper .market_feature_cards .market_card p {
  text-align: center;
  font-size: 1.5rem;

  color: white;
  font-family: "Poppins", sans-serif;
}

/* media querries */
@media (max-width: 1132px) {
  .market_feature_section .market_feature_wrapper h2 {
    padding-left: 20px;
  }

  .market_feature_section .market_feature_wrapper .market_feature_content {
    grid-template-columns: 1fr;
  }

  .market_feature_section
    .market_feature_wrapper
    .market_feature_content
    .right {
    order: -1;
  }
}

@media (max-width: 858px) {
  .page_header_container .page_header .main_heading {
    width: 100%;
    position: relative;
  }
}

@media (max-width: 768px) {
.page_header_container .page_header .main_heading h1 {
  margin-bottom: 10px;
}
  .market_feature_section
    .market_feature_wrapper
    .market_feature_content
    .left {
    background-image: none;
  }

  .market_features .market_feature_wrapper .market_feature_cards {
    grid-template-columns: repeat(1, 1fr);
  }
}
