/* page header starts */
.page_header_container {
  background-color: #0b163f;
  padding: 60px 0;
  overflow: hidden;
}

.page_header_container {
  background-image: url("../../img/starlink.png");
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: contain;
}

.page_header_container .page_header {
  width: 90%;
  margin: 0 auto;
  position: relative;
}

.page_header_container .page_header .main_heading {
  width: 70%;
}

.page_header_container .page_header .main_heading h1 {
  font-size: 3.1rem;
  color: white;

  font-weight: 900;
}

.page_header_container .page_header .main_heading p.lead {
  font-size: 1.6rem;
  color: #d6d5e1;
  text-align: justify;
  font-family: "Poppins", sans-serif;
}

/* feature section starts here */
.java_feature_section {
  background-color: #fffbfb;
  padding: 30px 0;
}

.java_feature_section .java_feature_wrapper {
  width: 90%;
  margin: 0 auto;
}

.java_feature_section .java_feature_wrapper .java_feature_content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.java_feature_section .java_feature_wrapper .java_feature_content .left {
  display: flex;
  background-image: url(../../img/shape/dot-big-square.png);
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;

  background-position: 12px 3px;
}

.java_feature_section .java_feature_wrapper .java_feature_content .left img {
  width: 70%;
}

.java_feature_section .java_feature_wrapper .java_feature_content .right {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.java_feature_section .java_feature_wrapper .java_feature_content .right p {
  font-size: 1.6rem;
  line-height: 1.7;
  color: #575757;
  text-align: justify;
  margin: 10px 0;
  font-weight: 0;
  font-family: "Poppins", sans-serif;
}

.java_feature_section .java_feature_wrapper .java_feature_content .right ul li {
  list-style-type: disc;
  list-style-position: outside;
  font-size: 1.6rem;
  margin: 15px 0;
  color: #575757;
  font-family: "Poppins", sans-serif;
}

.our_services {
  font-size: 1.5rem;
  display: inline-block;
  font-family: "Poppins", sans-serif;

  margin-top: 20px;
  color: #575757;
}

.java_feature_section .java_feature_wrapper .web_feature_heading h2 {
  text-align: center;
  font-size: 3rem;
  font-family: "Roboto", sans-serif;
  color: #071c4d;
}
.java_feature_section .java_feature_wrapper .web_feature_heading p {
  font-size: 1.6rem;
  text-align: center;
  margin: 10px 0;
  color: #575757;
  font-family: "Poppins", sans-serif;
}


.java_heading {
  font-size: 3rem;
  font-weight: 900;
  font-family: "Poppins", sans-serif;
}

/*--------------- media querries ----------*/

@media (max-width: 1132px) {
  .our_features .our_feature_wrapper .our_feature_cards {
    grid-template-columns: repeat(2, 1fr);
  }

  .java_feature_section .java_feature_wrapper .java_feature_content {
    grid-template-columns: 1fr;
  }
  .java_feature_section .java_feature_wrapper .java_feature_content .right {
    order: -1;
  }
}
@media (max-width: 858px) {
  .page_header_container .page_header .main_heading {
    width: 100%;
    position: relative;
  }
}



@media (max-width: 862px) {
  .page_header_container .page_header .main_heading h1 {
    font-size: 3.1rem;
    color: white;
    font-family: "Roboto", sans-serif;
    margin-bottom: 17px;
    font-weight: 900;
  }
}

@media (max-width: 602px) {
  .java_feature_section .java_feature_wrapper .java_feature_content .right{
    padding: 0;
  }
  .java_feature_section .java_feature_wrapper .java_feature_content .right ul {
   margin-left: 10px;

  }
}
