/* page header starts */
.page_header_container {
  background-color: #0b163f;
  padding: 60px 0;
  overflow: hidden;
}

.page_header_container {
  background-image: url("../../img/blur-banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: center;
}

.page_header_container .page_header {
  width: 90%;
  margin: 0 auto;
  position: relative;
}

.page_header_container .page_header .main_heading {
  width: 70%;
}

.page_header_container .page_header .main_heading h1 {
  font-size: 3.5rem;
  color: white;
  font-family: "Roboto", sans-serif;
  margin-bottom: 10px;
  font-weight: 900;
}

/* contact options styling */
.contact_options_section {
  padding: 50px 0;
}
.contact_options_section .main_wrapper {
  width: 90%;
  margin: 0 auto;
}
.contact_options_section .main_wrapper .options {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
/* .contact_options_section .main_wrapper .options .option span{
    height: 50px;
    width: 50px;
    justify-content: center;
    display: ali;
    align-items: center;
    display: inline-flex;
    border-radius: 15px;
    color: white;
    border: 1px solid red;
    background: #2c094d;
  } */
.contact_options_section .main_wrapper .options .option img {
  width: 16%;
  border-radius: 10px;
  box-shadow: 10px 10px 20px #c7c7c7, -10px -10px 20px #f9f9f9;
}

.contact_options_section .main_wrapper .options .option {
  display: flex;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
  box-shadow: 10px 10px 20px #c7c7c7, -10px -10px 20px #f9f9f9;
}

.contact_options_section .main_wrapper .options .option .icon {
  font-size: 3rem;
}
.contact_options_section .main_wrapper .options .option > a {
  width: 100%;
  height: 45px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  background: #175cff;
  color: white;
  font-size: 1.8rem;
  box-shadow: 10px 10px 20px #c7c7c7, -10px -10px 20px #f9f9f9;
  transition: .6s all linear;
}

.contact_options_section .main_wrapper .options .option > a:hover{
  background: #002c92;

}

.contact_options_section .main_wrapper .options .option h3 {
  font-size: 2.2rem;
  font-family: "Open Sans", sans-serif;
  margin: 12px 0;
}
.contact_options_section .main_wrapper .options .option p {
  text-align: center;
  color: #575757;
  font-size: 1.6rem;
  font-family:  'Poppins', sans-serif;
  margin-bottom: 10px;
}
.contact_options_section .main_wrapper .options .option .link {
  font-size: 1.8rem;
  font-family:  'Poppins', sans-serif;
}

/* Office section */

.our_office_section {
  margin-bottom: 50px;
}
.our_office_section .our_office {
  width: 90%;
  margin: 0 auto;
}

.our_office_section .our_office .office {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}
.our_office_section .our_office .our_office_heading {
  height: 150px;
  text-align: center;
}
.our_office_section .our_office .our_office_heading {
  margin-top: 50px;
  height: 150px;
  text-align: center;
}

.our_office_section .our_office .our_office_heading h3 {
  font-size: 2.5rem;
  color: #175cff;
  font-family: "Roboto", sans-serif;
}
.our_office_section .our_office .our_office_heading h2 {
  font-size: 3.8rem;
  color: #071c4d;
  font-family: "Roboto", sans-serif;
}

figure.snip0015 {
  font-family: "Raleway", Arial, sans-serif;
  color: #fff;
  position: relative;
  overflow: hidden;
  margin: 10px;
  max-height: 350px;
  width: 100%;
  background: #000000;
  text-align: center;
}
figure.snip0015 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
figure.snip0015 img {
  opacity: 1;
  width: 100%;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}
figure.snip0015 figcaption {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 3em 3em;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
figure.snip0015 figcaption::before {
  position: absolute;
  top: 50%;
  right: 30px;
  bottom: 50%;
  left: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
  content: "";
  opacity: 0;
  background-color: #ffffff;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;

  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
figure.snip0015 h2,
figure.snip0015 p {
  margin: 0 0 5px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, -webkit-transform 0.35s, -moz-transform 0.35s,
    -o-transform 0.35s, transform 0.35s;
    font-size: 2rem;
}
figure.snip0015 h2 {
  word-spacing: -0.15em;
  font-weight: 300;
  text-transform: uppercase;
  -webkit-transform: translate3d(0%, 50%, 0);
  transform: translate3d(0%, 50%, 0);
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
figure.snip0015 h2 span {
  font-weight: 800;
}
figure.snip0015 p {
  font-weight: 200;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  font-size: 2rem;
}
figure.snip0015 a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  color: #ffffff;
}
figure.snip0015:hover img {
  opacity: 0.35;
}
figure.snip0015:hover figcaption h2 {
  opacity: 1;
  -webkit-transform: translate3d(0%, 0%, 0);
  transform: translate3d(0%, 0%, 0);
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
figure.snip0015:hover figcaption p {
  opacity: 0.9;
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
figure.snip0015:hover figcaption::before {
  background: rgba(255, 255, 255, 0);
  top: 30px;
  bottom: 30px;
  opacity: 1;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

/* media querry */
@media (max-width: 1047px) {
  .contact_options_section .main_wrapper .options {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 952px) {
  .about_page_section
    .main_wrapper
    .about_page_container
    .about_page_content
    .cont2
    img {
    width: 70%;
  }
  .vacant {
    /* height: 0vh; */
    background-color: white;
  }
}

@media (max-width: 896px) {
  .our_office_section .our_office .office {
    grid-template-columns: repeat(2, 1fr);
  }
  .our_office_section .our_office .office .snip0015 figcaption h2 {
    font-size: 4.3rem;
  }
  .our_office_section .our_office .office .snip0015 figcaption p {
    font-size: 3.5rem;
  }
}

@media (max-width: 600px) {
  .contact_options_section .main_wrapper .options {
    grid-template-columns: repeat(1, 1fr);
  }
  .our_office_section .our_office .office {
    grid-template-columns: repeat(1, 1fr);
  }
  .our_office_section .our_office .office .snip0015 {
    width: 94%;
  }
  .our_office_section .our_office .office .snip0015 img {
    width: 100%;
    margin: 0;
    /* border: 2px solid red; */
  }

  .our_office_section .our_office .office .snip0015 figcaption h2 {
    font-size: 4.3rem;
  }
  .our_office_section .our_office .office .snip0015 figcaption p {
    font-size: 3.5rem;
  }
}

/* contact us form */
.contact_section {
  background-color: #0b163f;
  position: relative;
  overflow: hidden;
  /* background-image: url("../../img/banner.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
}
.contact_section .contact_wrapper {
  width: 90%;
  margin: 0 auto;
  padding: 30px 0;
}
.contact_section .vacant {
  height: 100px;
  background-color: #071c4d;
  width: 20%;
  position: absolute;
  bottom: -40px;
  left: 104px;
  border-radius: 5px;
}

.contact_section .contact_wrapper .main_content {
  display: grid;
  padding: 30px 0;
  column-gap: 100px;
  grid-template-columns: 1fr 40%;
}
.contact_section .contact_wrapper .main_content .seo_cont {
  padding: 0px 20px;
}
.contact_section .contact_wrapper .main_content .seo_cont h2 {
  font-size: 3.2rem;
  color: white;
  font-family: "Poppins", sans-serif;
  text-align: center;
  margin-bottom: 31px;
}
.contact_section .contact_wrapper .main_content .seo_cont p {
  font-size: 1.8rem;
  margin: 10px 0;

  color: #d6d5e1;
  font-family: "Open Sans", sans-serif;
}
.contact_section .contact_wrapper .main_content .seo_cont hr {
  color: #d6d5e1bd;
  background-color: #d6d5e1d3;
  border: 1px solid;
  opacity: 0.1;
}
.contact_section .contact_wrapper .main_content .contact_form_cont {
  padding: 20px;
  background-color: white;
  border-radius: 5px;
}
.contact_section .contact_wrapper .main_content .contact_form_cont h2 {
  text-align: center;
  color: black;
  font-size: 2.3rem;
  font-weight: 900;
  font-family: "Open Sans", sans-serif;
}
.contact_section
  .contact_wrapper
  .main_content
  .contact_form_cont
  .form
  .inputs_cont {
  padding: 10px;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.contact_section
  .contact_wrapper
  .main_content
  .contact_form_cont
  .form
  .inputs_cont
  .input
  input[type="text"]:focus {
  border: 2px solid #175cff;
}
.contact_section
  .contact_wrapper
  .main_content
  .contact_form_cont
  .form
  .inputs_cont
  .input
  select:focus {
  border: 2px solid #175cff;
}

.contact_section
  .contact_wrapper
  .main_content
  .contact_form_cont
  .form
  .inputs_cont
  .input
  input,
select {
  padding-left: 10px;
  margin: 10px;
  border: 2px solid #d6d5e1;
  height: 50px;
  width: 90%;
  border-radius: 5px;
  outline: none;
}

.opt {
  width: inherit;
}
.contact_section
  .contact_wrapper
  .main_content
  .contact_form_cont
  .form
  .inputs_cont
  .input_textarea {
  width: 200%;
  text-align: center;
}
.contact_section
  .contact_wrapper
  .main_content
  .contact_form_cont
  .form
  .inputs_cont
  .input_textarea
  textarea:focus {
  border: 2px solid #175cff;
}
.contact_section
  .contact_wrapper
  .main_content
  .contact_form_cont
  .form
  .inputs_cont
  .input {
  text-align: center;
}
.contact_section
  .contact_wrapper
  .main_content
  .contact_form_cont
  .form
  .inputs_cont
  .input_textarea
  textarea {
  padding: 20px;
  width: 93%;
  outline: none;
  border: 2px solid #d6d5e1;
  resize: none;
  border-radius: 5px;
}
.contact_section
  .contact_wrapper
  .main_content
  .contact_form_cont
  .form
  .form_button {
  text-align: center;
}
.contact_section
  .contact_wrapper
  .main_content
  .contact_form_cont
  .form
  .form_button
  button {
  width: 70%;
  background-color: black;
  color: white;
  height: 45px;
  border-radius: 5px;
  outline: none;
  transition: 0.5s all;
  cursor: pointer;
  border-radius: none;
  font-family: "Open Sans", sans-serif;
}
.contact_section
  .contact_wrapper
  .main_content
  .contact_form_cont
  .form
  .form_button
  button:hover {
  background-color: #175cff;
  border: none;
}

/* media querries */
@media (max-width: 996px) {
  .contact_section .contact_wrapper .soo_cont {
    width: 90%;
    margin-top: 12px;
  }
  .contact_section .contact_wrapper .main_content {
    grid-template-columns: 1fr;
  }
  .contact_section .contact_wrapper .main_content .subscribe_form_cont {
    order: -1;
    background: none;
  }
  .contact_section .contact_wrapper .main_content .subscribe_form_cont .form {
    width: 100%;
    padding: 20px;
    background-color: white;
  }
}

@media (max-width: 735px) {
  .contact_section .vacant {
    display: none;
  }

  .contact_section
    .contact_wrapper
    .main_content
    .subscribe_form_cont
    .form
    .inputs_cont {
    grid-template-columns: 1fr;
    border: #071c4d;
  }

  .contact_section .contact_wrapper .main_content .subscribe_form_cont {
    padding: 0;
    width: 100%;
  }
  .contact_section .contact_wrapper {
    width: 100%;
  }

  .contact_section
    .contact_wrapper
    .main_content
    .subscribe_form_cont
    .form
    .inputs_cont
    .input
    input,
  select {
    width: inherit;
    margin: 7px 0;
  }
  .contact_section
    .contact_wrapper
    .main_content
    .subscribe_form_cont
    .form
    .inputs_cont
    .input {
    width: 100%;
  }

  .contact_section
    .contact_wrapper
    .main_content
    .subscribe_form_cont
    .form
    .inputs_cont
    .input_textarea {
    width: 100%;
    text-align: center;
  }
  .contact_section
    .contact_wrapper
    .main_content
    .subscribe_form_cont
    .form
    .inputs_cont
    .input_textarea
    textarea {
    width: 100%;
  }
}
