.payment_wrapper {
  background-color: #0b163f;
  padding: 30px 0;
}
.payment_wrapper .inner_payment_section {
  width: 90%;
  padding: 40px;
  height: 400px;
  margin: 0 auto;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
.payment_wrapper .inner_payment_section .box1 {
  width: 15%;
  /* border: 1px solid white; */
}
.payment_wrapper .inner_payment_section .box1 .left {
  display: flex;
  justify-content: flex-end;
  transition: 0.5s linear;
}
.payment_wrapper .inner_payment_section .box3 .right {
  display: flex;
  justify-content: flex-end;
  transition: 0.5s linear;
}

.payment_wrapper .inner_payment_section .box2 {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.payment_wrapper .inner_payment_section .box1 img,
.box2 img,
.box3 img {
  width: 45px;
  height: auto;
  animation: round 2s linear infinite alternate-reverse;
}

@keyframes round {
  50% {
    transform: scale(1.2);
  }
}
.payment_wrapper .inner_payment_section .box3 {
  width: 15%;
  /* border: 1px solid white; */
}

.payment_wrapper .inner_payment_section .box2 h3 {
  text-align: center;
  font-size: 2rem;
  color: #175cff;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
}
.payment_wrapper .inner_payment_section .box2 h4 {
  text-align: center;
  font-size: 2.6rem;
  color: #ffffff;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
}
.payment_wrapper .inner_payment_section .box2 p {
  text-align: center;
  font-size: 1.9rem;
  color: #d6d5e1;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
}
.payment_wrapper .inner_payment_section .box2 .button {
  width: 60%;
  height: 55px !important;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  background-color: #175cff;
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 1.4rem;
  border: none;
  cursor: pointer;
}

/* media querry */
@media (max-width: 987px) {
  .payment_wrapper .inner_payment_section .box1,
  .payment_wrapper .inner_payment_section .box3 {
    display: flex;
    width: 100%;
    margin: 14px;
    justify-content: center;
  }

  .payment_wrapper .inner_payment_section .box1 img,
  .box2 img,
  .box3 img {
    width: 25px;
    box-shadow: 9px 3px 10px black;
    border-radius: 50%;
  }
  .payment_wrapper .inner_payment_section .box1 .payment_item,
  .box3 .payment_item {
    margin: 0 15px;
  }
  .payment_wrapper .inner_payment_section .box1 .left {
    justify-content: center;
  }
  .payment_wrapper .inner_payment_section .box3 .right {
    justify-content: center;
  }
  .payment_wrapper .inner_payment_section {
    flex-direction: column;
  }
}

@media (max-width: 832px) {
  .payment_wrapper .inner_payment_section .box2 h3 {
    font-size: 2rem;
  }
  .payment_wrapper .inner_payment_section .box2 h2 {
    font-size: 2.1rem;
  }
  .payment_wrapper .inner_payment_section .box2 p {
    font-size: 1.9rem;
  }
  .payment_wrapper .inner_payment_section .box2 .button {
    font-size: 1.3rem;
  }
}
@media (max-width: 724px) {
  .payment_wrapper .inner_payment_section .box2 h3 {
    font-size: 1.6rem;
  }
  .payment_wrapper .inner_payment_section .box2 h2 {
    font-size: 1.9rem;
  }
  .payment_wrapper .inner_payment_section .box2 p {
    font-size: 1.6rem;
  }
  .payment_wrapper .inner_payment_section .box2 button {
    font-size: 1.2rem;
  }
}
@media (max-width: 832px) {
  .payment_wrapper .inner_payment_section .box2 h3 {
    font-size: 2rem;
  }
  .payment_wrapper .inner_payment_section .box2 h2 {
    font-size: 2.1rem;
  }
  .payment_wrapper .inner_payment_section .box2 p {
    font-size: 1.9rem;
  }
  .payment_wrapper .inner_payment_section .box2 button {
    font-size: 1.3rem;
  }
}
@media (max-width: 406px) {
  .payment_wrapper .inner_payment_section .box2 {
    width: 100%;
  }
  .payment_wrapper .inner_payment_section .box2 .button {
    width: 100%;
  }
  .payment_wrapper .inner_payment_section .box2 button {
    width: 100%;
    font-size: 1.2rem;
  }
}
