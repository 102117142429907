/* font imports */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Poppins&family=Roboto:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Poppins&display=swap');
/* font alice */

html{
    font-size: 10px;
    scroll-behavior: smooth;
}

@media (max-width:770px){
    html{
        font-size: 8.8px !important;
    }
}
@media (max-width:570px){
    html{
        font-size: 8.5px !important;
    }
}

p,li{
    line-height: 1.7;
}
/* global fonts */
/* font-family:  'Poppins', sans-serif; */
/* font-family: 'Open Sans', sans-serif; */
/* font-family: 'Roboto', sans-serif; */
/* background-color: #fafafd; */
/* box shadow : 1px 2px 3px #fff1f1

best box shadow : box-shadow:  10px 10px 20px #c7c7c7,
                 -10px -10px 20px #f9f9f9;

*/

/* 

box-shadow:  10px 10px 20px #c7c7c7,
                 -10px -10px 20px #f9f9f9;

*/
/* global reset */
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

@media (max-width:700px){
    html{
        font-size: 119px;
    }
}