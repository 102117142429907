.page_header_container {
  background-color: #0b163f;
  padding: 60px 0;
  overflow: hidden;
}

.page_header_container {
  background-image: url("../../img/banner.png");

  background-repeat: no-repeat;
  background-size: cover;
}

.page_header_container .page_header {
  width: 90%;
  margin: 0 auto;
  position: relative;
  /* background-color: rgb(255, 255, 255); */
}

.page_header_container .page_header .plane_div {
  background-color: #071c4d;
  border-radius: 3px;
  position: absolute;
  height: 70px;
  width: 220px;
  right: 16%;
  bottom: -24%;
}

.page_header_container .page_header .main_heading {
  width: 60%;
}

.page_header_container .page_header .main_heading h1 {
  font-size: 3rem;
  color: white;
  font-family: "Roboto", sans-serif;
  margin-bottom: 10px;
  font-weight: 900;
}

.page_header_container .page_header .main_heading p.lead {
  font-size: 1.6rem;
  color: #d6d5e1;
  line-height: 1.7;
  text-align: justify;
  font-family: "Poppins", sans-serif;
}

/* feature section starts */
.feature_section {
  background-color: #fffbfb;
  padding: 30px 0;
}

.feature_section .feature_wrapper {
  width: 90%;
  margin: 0 auto;
}

.feature_section .feature_wrapper .feature_content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.feature_section .feature_wrapper .feature_content .left {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../../img/shape/dot-big-square.svg);
  background-repeat: no-repeat;
  background-position: top left;
}

.feature_section .feature_wrapper .feature_content .left img {
  width: 80%;
}

.feature_section .feature_wrapper .feature_content .right {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.feature_section .feature_wrapper .feature_content .right p {
  font-size: 1.6rem;
  line-height: 1.7;
  color: #575757;
  text-align: justify;
  font-weight: 0;
  font-family: "Poppins", sans-serif;
}

.feature_section .feature_wrapper .feature_content .right ul li {
  list-style-type: disc;
  list-style-position: outside;
  font-size: 1.6rem;
  margin: 15px 0;
  color: #575757;
  font-family: "Poppins", sans-serif;
}

.our_services {
  font-size: 1.5rem;
  display: inline-block;
  font-family: "Poppins", sans-serif;

  margin-top: 20px;
  color: #575757;
}

.feature_section .feature_wrapper .feature_heading h2 {
  text-align: center;
  font-size: 3rem;
  font-family: "Roboto", sans-serif;
  color: #0b163f;
}

/* our features sectin starts here */
.our_features {
  background-color: #fafafd;
  padding: 40px 0;
}
.our_features .our_feature_wrapper {
  width: 90%;
  margin: 0 auto;
}
.our_features .our_feature_wrapper .our_feature_heading {
  text-align: center;
  margin-bottom: 30px;
}
.our_features .our_feature_wrapper .our_feature_heading h3 {
  font-size: 3rem;
  color: #071c4d;
  margin: 12px 0;
  font-family: "Roboto", sans-serif;
}
.our_features .our_feature_wrapper .our_feature_heading p {
  font-size: 1.6rem;

  color: #575757;
  font-family: "Poppins", sans-serif;
}
.our_features .our_feature_wrapper .our_feature_cards {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(3, 1fr);
}
.our_features .our_feature_wrapper .our_feature_cards .card {
  display: flex;
  padding: 40px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #0b163f;
  border-radius: 10px;
}
.our_features .our_feature_wrapper .our_feature_cards .card .img {
  padding: 5px;
  border-radius: 11px;
  border-radius: 5px;
  transition: 5s all;
  border: 1px solid white;
  animation: blink 5s infinite alternate-reverse;
}
.our_features .our_feature_wrapper .our_feature_cards .card .img:hover {
  transform: translateY(-5px);
}
@keyframes blink {
  0% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(15deg);
  }
}
.our_features .our_feature_wrapper .our_feature_cards .card img {
  width: 30px;
}

.our_features .our_feature_wrapper .our_feature_cards .card h3 {
  font-size: 1.6rem;
  margin: 12px 0 0 0;
  text-align: center;
  color: white;
  font-family: "Poppins", sans-serif;
}

.our_features .our_feature_wrapper .our_feature_cards .card p {
  text-align: center;
  font-size: 1.4rem;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
}

/*  work process section*/

.work_process_container {
  background-color: #fffbfb;
}
.work_process_container .content_wrapper {
  width: 90%;
  margin: 0 auto;
}
.work_process_container .content_wrapper .heading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 40px;
}

.work_process_container .content_wrapper .heading h3 {
  font-size: 3rem;
  font-family: "Open Sans", sans-serif;
  color: #175cff;
}
.work_process_container .content_wrapper .heading h2 {
  font-size: 2.5rem;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: #071c4d;
}
.work_process_container .content_wrapper .heading p {
  margin: 10px 0;
  font-size: 1.6rem;
  text-align: center;
  color: #575757;

  font-family: "Poppins", sans-serif;
}

.work_process_container .content_wrapper .content_section {
  display: flex;
  /* background-color: blue; */
}
.work_process_container .content_wrapper .content_section .images_section {
  width: 50%;
  display: flex;
  flex-direction: column;
  column-gap: 10px;
  row-gap: 50px;
  /* background-color: rgb(192, 234, 25); */
}
.work_process_container .content_wrapper .content_section .images_section img {
  width: 90%;
}
.work_process_container .content_wrapper .content_section .text_content_cont {
  width: 50%;
  display: flex;
  gap: 15px;
  /* background-color: rgb(7, 166, 95); */
}

.work_process_container
  .content_wrapper
  .content_section
  .text_content_cont
  .tree_structure {
  width: 100px;
  padding: 10px;
}

.work_process_container
  .content_wrapper
  .content_section
  .text_content_cont
  .tree_structure
  .timeline_icon {
  height: 70px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #e4d4d4;
}

.work_process_container
  .content_wrapper
  .content_section
  .text_content_cont
  .tree_structure
  .timeline_icon
  .ic {
  font-size: 3.2rem;
  color: #e4d4d4;
}

.work_process_container
  .content_wrapper
  .content_section
  .text_content_cont
  .tree_structure
  .timeline_icon
  .ic:hover {
  font-size: 3.2rem;
  color: #0d6efd;
}

.work_process_container
  .content_wrapper
  .content_section
  .text_content_cont
  .tree_structure
  .line {
  height: 100px;
  border-right: 2px dotted;
  margin: 0 auto;
  width: 0px;
  color: #e4d4d4;
}

.work_process_container
  .content_wrapper
  .content_section
  .text_content_cont
  .text_cont
  h2 {
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
  margin: 6px 0;
  color: #071c4d;
}
.work_process_container
  .content_wrapper
  .content_section
  .text_content_cont
  .text_cont
  p {
  font-size: 1.6rem;
  font-family: "Poppins", sans-serif;
  color: #575757;
}

.work_process_container
  .content_wrapper
  .content_section
  .text_content_cont
  .text_cont
  div {
  margin-bottom: 50px;
}

/* industries we work with */

/* media querry of work process or why us*/
@media (max-width: 1110px) {
  .work_process_container
    .content_wrapper
    .content_section
    .text_content_cont
    .text_cont
    h2 {
    font-size: 1.6rem;
    font-family: "Poppins", sans-serif;
    margin: 6px 0;
  }
  .work_process_container
    .content_wrapper
    .content_section
    .text_content_cont
    .text_cont
    p {
    font-size: 1.6rem;
    font-family: "Poppins", sans-serif;
  }
}
@media (max-width: 858px) {
  .page_header_container .page_header .main_heading {
    width: 100%;
    position: relative;
  }
}

@media (max-width: 805px) {
  .work_process_container .content_wrapper .content_section {
    flex-direction: column-reverse;
  }
  .work_process_container .content_wrapper .content_section .text_content_cont {
    width: 100%;
  }
  .work_process_container
    .content_wrapper
    .content_section
    .text_content_cont
    .tree_structure
    .line {
    height: 50px;
    border-right: 2px dotted;
    margin: 0 auto;
    width: 0px;
  }
}
@media (max-width: 794px) {
  .work_process_container .content_wrapper .content_section .images_section {
    width: 100%;
    align-items: center;
  }
}

@media (max-width: 651px) {
  .work_process_container
    .content_wrapper
    .content_section
    .text_content_cont
    .text_cont
    h2 {
    font-size: 1.6rem;
    font-family: "Open Sans", sans-serif;
  }

  .work_process_container
    .content_wrapper
    .content_section
    .text_content_cont
    .tree_structure
    .line {
    height: 39px;
  }
}

/* media querries */

@media (max-width: 1192px) {
  .our_features .our_feature_wrapper .our_feature_cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 995px) {
  .page_header_container .page_header .plane_div {
    display: none;
  }

  .feature_section .feature_wrapper .feature_content {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  .feature_section .feature_wrapper .feature_heading {
    margin-bottom: 10px;
  }

  .feature_section .feature_wrapper .feature_content .right {
    order: -1;
  }
}

@media (max-width: 855px) {
  .our_features {
    background-image: none;
  }
  .our_features .our_feature_wrapper .our_feature_cards {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
}

@media (max-width: 714px) {
  .feature_section .feature_wrapper .feature_content .left {
    background-image: none;
    height: fit-content;
  }
}

@media (max-width: 602px) {
  .feature_section .feature_wrapper .feature_content .left {
    background-image: none;
    height: fit-content;
  }
}
