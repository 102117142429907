.wrapper {
  display: flex;
  height: 30vh;
  align-items: center;
  justify-content: center;
}
/* .wrapper img{
    width: 5%;
} */
.wrapper .spin {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 0.5s linear infinite;

  border-bottom: 1px solid blue;
  border-top: 1px solid red;
  box-shadow: 0px -1px 8px rgb(0 0 0);
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
