/* page header starts */
.page_header_container {
  background-color: #0b163f;
  padding: 60px 0;
  overflow: hidden;
}

.page_header_container {
  background-image: url("../../img/banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.page_header_container .page_header {
  width: 90%;
  margin: 0 auto;
  position: relative;
}

.page_header_container .page_header .main_heading {
  width: 70%;
}

.page_header_container .page_header .main_heading h1 {
  font-size: 3.1rem;
  color: white;
  font-family: "Roboto", sans-serif;
  margin-bottom: 10px;
  font-weight: 900;
}

.page_header_container .page_header .main_heading p.lead {
  font-size: 1.6rem;
  color: #d6d5e1;
  line-height: 1.7;
  font-family: "Poppins", sans-serif;
}

/* top or first feature section */
.first_feature_section {
  background-color: #fffbfb;
  padding-top: 30px;
  padding-bottom: 60px;
}

.first_feature_section .first_feature_wrapper {
  width: 90%;
  margin: 0 auto;
}

.first_feature_section .first_feature_wrapper .first_feature_cont {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
}

.first_feature_section .first_feature_wrapper .first_feature_cont .left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.first_feature_section .first_feature_wrapper .first_feature_cont .left img {
  width: 70%;
}

.first_feature_section .first_feature_wrapper .first_feature_cont .right {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.first_feature_section .first_feature_wrapper .first_feature_cont .right p {
  font-size: 1.6rem;
  text-align: justify;
  color: #575757;

  font-family: "Poppins", sans-serif;
}

.first_feature_section .first_feature_wrapper  h2 {
  font-size: 3rem;
  font-family: "Roboto", sans-serif;
  color: #071c4d;
  font-weight: 900;
  margin-bottom: 12px;
  text-align: center;
}

/* our promise section */

/* top or first feature section */
.promise_feature_section {
  background-color: #fffbfb;
  padding: 30px 0;
  overflow: hidden;
}

.promise_feature_section .promise_feature_wrapper {
  width: 90%;
  margin: 0 auto;
}

.promise_feature_section .promise_feature_wrapper .promise_feature_content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.promise_feature_section
  .promise_feature_wrapper
  .promise_feature_content
  .left {
  display: flex;
  background-image: url(../../img/shape/dot-big-square.png);
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  position: relative;
  background-position: 12px 3px;
  transition: 0.5s all;
}

.promise_feature_section
  .promise_feature_wrapper
  .promise_feature_content
  .left
  img {
  width: 80%;
}
.promise_feature_section
  .promise_feature_wrapper
  .promise_feature_content
  .left
  img.asset {
  position: absolute;
  width: 25%;
}
.promise_feature_section
  .promise_feature_wrapper
  .promise_feature_content
  .left
  img.asset1 {
  top: -4%;
  right: -2%;
}
.promise_feature_section
  .promise_feature_wrapper
  .promise_feature_content
  .left
  img.asset1_animate {
  animation: move1 4s linear infinite alternate-reverse;
}
.promise_feature_section
  .promise_feature_wrapper
  .promise_feature_content
  .left
  img.asset2_animate {
  animation: move1 4s linear infinite alternate-reverse;
}
.promise_feature_section
  .promise_feature_wrapper
  .promise_feature_content
  .left
  img.asset3_animate {
  animation: spin 4s linear infinite alternate-reverse;
}
@keyframes move1 {
  50% {
    transform: translateY(-15px);
    transform: rotate(15deg);
  }
  100% {
    transform: translateY(5px);
    transform: translateX(5px);
  }
}
@keyframes spin {
  50% {
    transform: scale(0.8);
    transform: rotate(45deg);
  }
  100% {
    transform: scale(1.2);
  }
}
.promise_feature_section
  .promise_feature_wrapper
  .promise_feature_content
  .left
  img.asset2 {
  left: -4%;
  bottom: 3%;
}
.promise_feature_section
  .promise_feature_wrapper
  .promise_feature_content
  .left
  img.asset3 {
  bottom: 0%;
  right: 0;
  width: 20%;
}

.promise_feature_section
  .promise_feature_wrapper
  .promise_feature_content
  .right {
  padding: 20px;
  display: flex;
  flex-direction: column;
  order: -1;
}
.promise_feature_section
  .promise_feature_wrapper
  .promise_feature_content
  .right
  h2 {
  font-size: 3.5rem;
  font-family: "Poppins", sans-serif;
  color: #071c4d;
  margin-bottom: 15px;
}

.promise_feature_section
  .promise_feature_wrapper
  .promise_feature_content
  .right
  p {
  font-size: 1.6rem;
    text-align: justify;
  color: #575757;
  font-weight: 0;
  font-family: "Poppins", sans-serif;
}

.promise_feature_section
  .promise_feature_wrapper
  .promise_feature_content
  .right
  ul
  li {
  list-style-type: disc;
  list-style-position: outside;
  font-size: 1.6rem;
  margin: 10px 0;
  color: #575757;

  font-family: "Poppins", sans-serif;
}

.our_services {
  font-size: 1.5rem;
  display: inline-block;
  font-family: "Poppins", sans-serif;

  margin-top: 20px;
  color: #575757;
}

.promise_feature_section .promise_feature_wrapper .promise_feature_heading {
  margin-bottom: 20px;
}

.promise_feature_section .promise_feature_wrapper .promise_feature_heading h2 {
  text-align: center;
  font-size: 3rem;
  font-family: "Roboto", sans-serif;
  color: #071c4d;
}

.work_process_container {
  background-color: #fffbfb;
}
.work_process_container .content_wrapper {
  width: 90%;
  margin: 0 auto;
}
.work_process_container .content_wrapper .heading {
  display: flex;
  width: 80%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;

}

.work_process_container .content_wrapper .heading h3 {
  font-size: 2rem;
  font-family: "Open Sans", sans-serif;
  color: #175cff;
}
.work_process_container .content_wrapper .heading h2 {
  font-size: 2.5rem;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: #071c4d;
}
.work_process_container .content_wrapper .heading p {
  margin: 10px;
  font-size: 1.6rem;
  text-align: center;
  color: #575757;
  font-family: "Poppins", sans-serif;
}

.work_process_container .content_wrapper .content_section {
  display: flex;
}
.work_process_container .content_wrapper .content_section .images_section {
  width: 50%;
  display: flex;
  flex-direction: column;
  column-gap: 10px;
  row-gap: 50px;
}
.work_process_container .content_wrapper .content_section .images_section img {
  width: 90%;
}
.work_process_container .content_wrapper .content_section .text_content_cont {
  width: 50%;
  display: flex;
  gap: 15px;
}

.work_process_container
  .content_wrapper
  .content_section
  .text_content_cont
  .tree_structure {
  width: 100px;
  padding: 10px;
}

.work_process_container
  .content_wrapper
  .content_section
  .text_content_cont
  .tree_structure
  .timeline_icon {
  height: 70px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #e4d4d4;
}

.work_process_container
  .content_wrapper
  .content_section
  .text_content_cont
  .tree_structure
  .timeline_icon
  .ic {
  font-size: 3.2rem;
  color: #e4d4d4;
}

.work_process_container
  .content_wrapper
  .content_section
  .text_content_cont
  .tree_structure
  .timeline_icon
  .ic:hover {
  font-size: 3.2rem;
  color: #0d6efd;
}

.work_process_container
  .content_wrapper
  .content_section
  .text_content_cont
  .tree_structure
  .line {
  height: 125px;
  border-right: 2px dotted;
  margin: 0 auto;
  width: 0px;
  color: #e4d4d4;
}

.work_process_container
  .content_wrapper
  .content_section
  .text_content_cont
  .text_cont
  h2 {
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
  margin: 6px 0;
}
.work_process_container
  .content_wrapper
  .content_section
  .text_content_cont
  .text_cont
  p {
  font-size: 1.6rem;
  color: #575757;
    text-align: justify;
  font-family: "Poppins", sans-serif;
}

.work_process_container
  .content_wrapper
  .content_section
  .text_content_cont
  .text_cont
  div {
  margin-bottom: 50px;
}

/* types of technolgies we use for Ecommerce */

.services_container .service_section {
  width: 90%;
  margin: 0 auto;
}
.services_container .service_section .heading_cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.services_container .service_section .heading_cont h2 {
  font-size: 3rem;
  margin: 10px 0 0 0;
  font-family: sans-serif;
  font-weight: 900;
  color: #071c4d;
}
.services_container .service_section .heading_cont p {
  font-size: 2rem;
  font-family: sans-serif;
  color: #575757;
}
.services_container .service_section .service_item_cont {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto;
  overflow: hidden;
  padding: 30px 0;
}
.services_container .service_section .service_item_cont .service_item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 40px;
  padding: 25px 10px;
  background-color: #0b163f;
  box-shadow: 10px 10px 20px #c7c7c7, -10px -10px 20px #f9f9f9;
}
.services_container .service_section .service_item_cont .service_item h2 {
  font-size: 2.5rem;
  color: white;
  font-family: "Roboto", sans-serif;
  margin: 20px 0;
}
.services_container .service_section .service_item_cont .service_item .link {
  color: var(--bs-info-dark);
}
.services_container .service_section .service_item_cont .service_item p {
  font-size: 1.5rem;
  color: white;
  font-family: "Poppins", sans-serif;
}

/* media querries */
@media (max-width: 980px) {
  .services_container .service_section .service_item_cont {
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;
  }
  .first_feature_section .first_feature_wrapper .first_feature_cont {
    grid-template-columns: repeat(1, 1fr);
  }
  .first_feature_section .first_feature_wrapper .first_feature_cont .left {
    order: 1;
  }

  .first_feature_section .first_feature_wrapper .first_feature_cont h2 {
    margin-bottom: 12px;
  }
}

@media (max-width: 1132px) {
  html {
    font-size: 12px;
  }
  .industries_work_section .industry_work_wrapper .deals_with_cont {
    grid-template-columns: repeat(5, 1fr);
  }
  .our_features .our_feature_wrapper .our_feature_cards {
    grid-template-columns: repeat(2, 1fr);
  }

  .promise_feature_section .promise_feature_wrapper .promise_feature_content {
    grid-template-columns: 1fr;
  }
  .promise_feature_section
    .promise_feature_wrapper
    .promise_feature_content
    .right {
    order: -1;
  }
}
@media (max-width: 868px) {
  .page_header_container .page_header .main_heading {
    width: 100%;
    position: relative;
  }
}
@media (max-width: 933px) {
  .promise_feature_section
    .promise_feature_wrapper
    .promise_feature_content
    .left {
    background-image: none;
  }
}

@media (max-width: 1110px) {
  .work_process_container
    .content_wrapper
    .content_section
    .text_content_cont
    .text_cont
    h2 {
    font-size: 1.6rem;
    font-family: "Poppins", sans-serif;
    margin: 6px 0;
  }
  .work_process_container
    .content_wrapper
    .content_section
    .text_content_cont
    .text_cont
    p {
    font-size: 1.6rem;
    font-family: "Poppins", sans-serif;
  }
}

@media (max-width: 805px) {
  .work_process_container .content_wrapper .content_section {
    flex-direction: column-reverse;
  }
  .work_process_container .content_wrapper .content_section .text_content_cont {
    width: 100%;
  }
  .work_process_container
    .content_wrapper
    .content_section
    .text_content_cont
    .tree_structure
    .line {
    height: 50px;
    border-right: 2px dotted;
    margin: 0 auto;
    width: 0px;
  }
}
@media (max-width: 794px) {
  .work_process_container .content_wrapper .content_section .images_section {
    width: 100%;
    align-items: center;
  }
}

@media (max-width: 642px) {
  .services_container .service_section .service_item_cont {
    grid-template-columns: auto;
    grid-template-rows: repeat(6, 1fr);
  }
.first_feature_section .first_feature_wrapper .first_feature_cont .right {
  padding: 0;
}

.promise_feature_section
.promise_feature_wrapper
.promise_feature_content
.right {
padding: 0;
}
.promise_feature_section
.promise_feature_wrapper
.promise_feature_content
.right ul{
padding-left: 10px;
}
  .first_feature_section .first_feature_wrapper .first_feature_cont h2 {
    font-size: 2.8rem;
  }

  .work_process_container
    .content_wrapper
    .content_section
    .text_content_cont
    .text_cont
    h2 {
    font-size: 1.8rem;
    font-family: "Poppins", sans-serif;

  }

  .work_process_container
    .content_wrapper
    .content_section
    .text_content_cont
    .tree_structure
    .line {
    height: 131px;
  }
}

@media (max-width: 588px) {
  .work_process_container .content_wrapper .heading {
    width: 100%;
  }
}
