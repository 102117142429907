/* about page css starts here */
.about_page_section {
  background-color: #0b163f;
  margin-top: -2px;
}
.about_page_section .main_wrapper {
  width: 90%;
  margin: 0 auto;
}

.about_page_section .main_wrapper .about_page_container {
  /* background-color: rgb(0, 202, 13); */
  display: flex;
  position: relative;
  top: 100px;
}
.about_page_section .main_wrapper .about_page_container .image {
  /* background-color: rgb(197, 204, 223); */
  display: flex;
  width: 50%;
  align-items: center;
  gap: 40px;
  flex-direction: column;
}
.about_page_section .main_wrapper .about_page_container .image img {
  width: 80%;
}
.about_page_section .main_wrapper .about_page_container .about_page_content {
  /* background-color: rgb(209, 22, 22); */
  display: flex;
  width: 50%;
  flex-direction: column;
}

.about_page_section
  .main_wrapper
  .about_page_container
  .about_page_content
  .cont1 {
  /* border: 1px solid white; */
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 60px;
}
.about_page_section
  .main_wrapper
  .about_page_container
  .about_page_content
  .cont1
  h1 {
  font-size: 3rem;
  color: white;
  font-family: "Poppins", sans-serif;
}
.about_page_section
  .main_wrapper
  .about_page_container
  .about_page_content
  .cont1
  p {
  font-size: 2rem;
  color: white;
  font-family: "Open Sans", sans-serif;
}
.about_page_section
  .main_wrapper
  .about_page_container
  .about_page_content
  .cont1
  .btns {
  /* border: 4px solid white; */
  display: flex;
  gap: 40px;
}
.about_page_section
  .main_wrapper
  .about_page_container
  .about_page_content
  .cont1
  .btns
  .link {
  width: 34%;
  height: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content:center ;
  border: none;
  cursor: pointer;
  transition: 0.5s;
}
.about_page_section
  .main_wrapper
  .about_page_container
  .about_page_content
  .cont1
  .btns
  .link:hover {
  background-color: rgb(57, 57, 188) !important;
  color: white !important;
}
.about_page_section
  .main_wrapper
  .about_page_container
  .about_page_content
  .cont1
  .btns
  .link:nth-child(2) {
  background-color: #ceddff;
  color: rgb(68, 68, 248);
  font-size: 1.5rem;
}
.about_page_section
  .main_wrapper
  .about_page_container
  .about_page_content
  .cont1
  .btns
  .link:nth-child(1) {
   
  background-color: rgb(68, 68, 248);
  color: #ceddff;
  font-size: 1.5rem;
}

.about_page_section
  .main_wrapper
  .about_page_container
  .about_page_content
  .cont2
  img {
  /* border: 1px solid black; */
  width: 80%;
}

.vacant {
  height: 20vh;
  background-color: white;
}
.vacant .temp_image {
  visibility: hidden;
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
}
.vacant .temp_image img {
  /* display: none; */
  width: 47%;
  /* height: 296px; */
  height: 60%;
}

/* --------------Story section--------------- */
.our_story_wrapper {
 padding-top: 80px;
  
}
.our_story_wrapper .story_wrapper_main {
  width: 90%;
  margin: 0 auto;
}
.our_story_wrapper .story_wrapper_main .story_content {
  display: grid;
  /* background-color: rgb(32, 0, 113); */
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 80px;
}
.our_story_wrapper .story_wrapper_main .story_content .numbers {
  display: grid;
  padding: 0 0 55px 0;
  background-repeat: no-repeat;
  background-position: bottom;
  background-position-x: right;
  grid-template-columns: repeat(2, 1fr);
}
.our_story_wrapper .story_wrapper_main .story_content .numbers .number_box {
  /* border: 1px solid white; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 230px;
}
.our_story_wrapper .story_wrapper_main .story_content .numbers .number_box h2 {
  font-size: 3rem;

  font-family: "Poppins", sans-serif;
}
.our_story_wrapper .story_wrapper_main .story_content .numbers .number_box p {
  font-size: 1.8rem;
  font-family: "Open Sans", sans-serif;
}

.our_story_wrapper .story_wrapper_main .story_content .story_cont .our_awards {
  display: flex;
  flex-direction: column;
  /* border: 2px solid white; */
}
.our_story_wrapper
  .story_wrapper_main
  .story_content
  .story_cont
  .our_awards
  img {
  width: 25%;
}
.our_story_wrapper .story_wrapper_main .story_content .story_cont h3 {
  font-size: 2rem;
  color: #175cff;
  font-family: "Open Sans", sans-serif;
}

.our_story_wrapper .story_wrapper_main .story_content .story_cont h2 {
  font-size: 3rem;
  color: #071c4d;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}

.our_story_wrapper .story_wrapper_main .story_content .story_cont p {
  font-size: 1.6rem;
  text-align: justify;
  font-family:  'Poppins', sans-serif;
  margin-bottom: 20px;
  color: #575757;
}

.our_story_wrapper
  .story_wrapper_main
  .story_content
  .story_cont
  .our_awards
  h2 {
  color: rgb(1 14 43 / 72%);
  font-size: 2rem;
  margin-bottom: 20px;
}
.our_story_wrapper
  .story_wrapper_main
  .story_content
  .story_cont
  .our_awards
  .award_images {
  display: flex;
  gap: 20px;
}

/*How We work section starts here  */

.how_we_work_section {
  padding-top: 50px;
  margin-bottom: 50px;
}
.how_we_work_section .work_container {
  width: 90%;
  margin: 0 auto;
  display: flex;

  gap: 30px;
}

.how_we_work_section .work_container .work_content_wrapper {
  width: 50%;
  height: fit-content;
}
.how_we_work_section .work_container .work_content_wrapper h2 {
  font-size: 1.5rem;
  color: #175cff;
  font-family: "Open Sans", sans-serif;
  /* background-color: red; */
  margin-bottom: 20px;
}

.how_we_work_section .work_container .work_content_wrapper .lists_cont li {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 900;
  font-size: 1.5rem;
}
.how_we_work_section .work_container .work_content_wrapper .lists_cont li span {
  display: inline-block;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  align-items: center;
  margin-right: 20px;
}

.how_we_work_section .work_container .work_img_section {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.how_we_work_section .work_container .work_img_section .reach_us {
  position: absolute;
  bottom: 30px;
  width: 80%;
}

.how_we_work_section .work_container .work_img_section img {
  width: 100%;
  height: 100%;
  /* border: 2px solid red; */
}

.how_we_work_section
  .work_container
  .work_content_wrapper
  .lists_cont
  li
  .icon {
  font-size: 3rem;
  color: white;
}
.how_we_work_section
  .work_container
  .work_content_wrapper
  .lists_cont
  li
  .gear {
animation: spin 3s linear infinite;
}

@keyframes spin{
  100%{
    transform: rotate(360deg);
  }
}

/* work-img-section */

.how_we_work_section .work_container .work_img_section .reach_us .box {
  height: 90px;
  display: flex;
  padding: 20px 20px;
  align-items: center;
  /* border: 2px solid wheat; */
  border-radius: 4px;
  width: 100%;
  background-color: white;
}

.how_we_work_section .work_container .work_img_section .reach_us .box a {
  font-size: 1.8rem;
}
.how_we_work_section .work_container .work_img_section .reach_us .phone {
  margin-bottom: 20px;
}
.how_we_work_section .work_container .work_img_section .reach_us .phone h6 {
  font-size: 1.2rem;
  margin-right: 6px;
}
.how_we_work_section .work_container .work_img_section .reach_us .phone h6 a {
  color: #3a75ff;
}
.how_we_work_section .work_container .work_img_section .reach_us .email a {
  height: 60px;
  width: 150px;
  padding: auto 0;
  background-color: #175cff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: white;
  text-decoration: none;
  font-size: 2rem;
  font-family: "Open Sans", sans-serif;
}
.how_we_work_section .work_container .work_img_section .reach_us .box h6 {
  font-size: 1.8rem;
  font-family: "Poppins", sans-serif;
}
.how_we_work_section .work_container .work_img_section .reach_us .box .icon {
  height: 40px;
  width: 40px;
  display: flex;
  margin-right: 10px;
  align-items: center;
  color: #175cff;
}

/* How we work section ends here */

/* Testimonial starts here */

/* scroll bar styling */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.testimonial_section .testimonial_wrapper {
  width: 90%;
  margin: 0 auto;
  overflow: hidden;
}
.testimonial_section .testimonial_wrapper .testimonial-heading {
  text-align: center;
  padding: 10px 0;
}
.testimonial_section .testimonial_wrapper .testimonial_heading h3 {
  font-size: 2rem;
  color: #175cff;
  font-family: "Roboto", sans-serif;
}
.testimonial_section .testimonial_wrapper .testimonial_heading h2 {
  font-size: 3rem;
  color: #071c4d;
  margin: 10px 0;
  font-weight: 900;
  font-family: "Poppins", sans-serif;
}
.testimonial_section .testimonial_wrapper .testimonial_heading p {
  font-size: 1.4rem;
  color: #575757;
}

.testimonial_section .testimonial_wrapper .testimonial_content_wrapper {
  padding: 20px;
  width: 100%;
  overflow: hidden;
  /* border: 1px solid; */
}

.swiper {
/* border: 2px solid;  */
padding-bottom: 40px !important;
}



.testimonial_section
  .testimonial_wrapper
  .testimonial_content_wrapper
  .testimonial
  .void {
  height: 50px;
  /* background-color: #0b163f; */
}

.testimonial_section
  .testimonial_wrapper
  .testimonial_content_wrapper
  .testimonial
  .about_user {
  position: relative;
  background-color: white;
  display: flex;
  padding: 90px 20px 20px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  box-shadow:  10px 10px 20px #c7c7c7,
  -10px -10px 20px #f9f9f9;
}
.testimonial_section
  .testimonial_wrapper
  .testimonial_content_wrapper
  .testimonial
  .about_user
  h6 {
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  text-align: justify;
}
.testimonial_section
  .testimonial_wrapper
  .testimonial_content_wrapper
  .testimonial
  .about_user
  span {
  margin: 10px 0;
}
.testimonial_section
  .testimonial_wrapper
  .testimonial_content_wrapper
  .testimonial
  .about_user
  h2 {
  font-size: 2rem;
  color: #071c4d;
  font-family: "Roboto", sans-serif;
}
.testimonial_section
  .testimonial_wrapper
  .testimonial_content_wrapper
  .testimonial
  .about_user
  h3 {
  font-size: 1.6rem;
  text-align: center;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.testimonial_section
  .testimonial_wrapper
  .testimonial_content_wrapper
  .testimonial
  .about_user
  img {
  position: absolute;
  width: 30%;
  top: -45px;
  left: 34%;
  border-radius: 50%;
  box-shadow: 2px 1px 3px black;
  position: absolute;
}

/* Testimonial Ends here */

/* Our office starts here */

.our_office_section {
  margin-bottom: 50px;
}
.our_office_section .our_office {
  width: 90%;
  margin: 0 auto;
}

.our_office_section .our_office .office {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}
.our_office_section .our_office .our_office_heading {
  height: 150px;
  text-align: center;
}
.our_office_section .our_office .our_office_heading {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.our_office_section .our_office .our_office_heading h3 {
  font-size: 2.5rem;
  color: #175cff;
  font-family: "Roboto", sans-serif;
}
.our_office_section .our_office .our_office_heading h2 {
  font-size: 3.8rem;
  color: #071c4d;
  font-family: "Roboto", sans-serif;
}

figure.snip0015 {
  font-family: "Raleway", Arial, sans-serif;
  color: #fff;
  position: relative;
  overflow: hidden;
  margin: 10px;
  max-height: 350px;
  width: 100%;
  background: #000000;
  text-align: center;
}
figure.snip0015 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
figure.snip0015 img {
  opacity: 1;
  width: 100%;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}
figure.snip0015 figcaption {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 3em 3em;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
figure.snip0015 figcaption::before {
  position: absolute;
  top: 50%;
  right: 30px;
  bottom: 50%;
  left: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
  content: "";
  opacity: 0;
  background-color: #ffffff;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;

  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
figure.snip0015 h2,
figure.snip0015 p {
  margin: 0 0 5px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, -webkit-transform 0.35s, -moz-transform 0.35s,
    -o-transform 0.35s, transform 0.35s;
}
figure.snip0015 h2 {
  word-spacing: -0.15em;
  font-weight: 300;
  text-transform: uppercase;
  -webkit-transform: translate3d(0%, 50%, 0);
  transform: translate3d(0%, 50%, 0);
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
figure.snip0015 h2 span {
  font-weight: 800;
}
figure.snip0015 p {
  font-weight: 200;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  font-size: 2rem;
}
figure.snip0015 a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  color: #ffffff;
}
figure.snip0015:hover img {
  opacity: 0.35;
}
figure.snip0015:hover figcaption h2 {
  opacity: 1;
  -webkit-transform: translate3d(0%, 0%, 0);
  transform: translate3d(0%, 0%, 0);
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
figure.snip0015:hover figcaption p {
  opacity: 0.9;
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
figure.snip0015:hover figcaption::before {
  background: rgba(255, 255, 255, 0);
  top: 30px;
  bottom: 30px;
  opacity: 1;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

/* our office ended here */

.trial_section {
  background-color: #0b163f;
}

.trial_section .subscription_wrapper {
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  align-content: center;
  justify-items: center;
  padding: 50px;
}
.trial_section .subscription_wrapper .start_trial_text {
  padding: 20px 0;
  text-align: center;
  width: 100%;
}
.trial_section .subscription_wrapper .start_trial_text h3 {
  color: #ffb116;
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
}
.trial_section .subscription_wrapper .start_trial_text h2 {
  font-size: 3rem;
  font-weight: 900;
  margin: 20px 0;
  font-family: "Poppins", sans-serif;
  color: white;
}
.trial_section .subscription_wrapper .start_trial_text p {
  font-size: 1.8rem;
  color: #d6d5e1;
  font-family: "Open Sans", sans-serif;
}

.trial_section .subscription_wrapper .subscribe_cont {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 113px;
}

.trial_section .subscription_wrapper .subscribe_cont .link {
  width: 150px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  border: none;
  background-color: #175cff;
  color: white;
  font-family: "Open Sans", sans-serif;
  border-radius: 5px;
  cursor: pointer;
}
.trial_section .subscription_wrapper .subscribe_cont .playbox span {
  font-size: 3rem;

  margin-right: 15px;
}
.trial_section .subscription_wrapper .subscribe_cont .playbox p {
  font-size: 1.5rem;
  text-align: center;
  color: white;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 10px;
}

.trial_section .subscription_wrapper .subscribe_cont .playbox {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding-top: 10px;
}


#app{
  height: 100%;
  width: 100%;
  background-color: red;
}
.trial_section .subscription_wrapper .subscribe_feature_list ul li {
  font-size: 1.5rem;
  color: white;
  font-family: "Open Sans", sans-serif;
}

.trial_section .subscription_wrapper .subscribe_feature_list ul {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(4, 1fr);
}

/* Subscription Section ended here */

/* media querries */

@media (max-width: 1060px) {
  .trial_section .subscription_wrapper .subscribe_feature_list ul {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}

@media (max-width: 996px) {
  .our_story_wrapper .story_wrapper_main .story_content {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    /* border: 1px solid black; */
  }
  .our_story_wrapper .story_wrapper_main .story_content .numbers {
    /* border: 1px solid black; */
    order: 1;
  }
  .our_story_wrapper
    .story_wrapper_main
    .story_content
    .story_cont
    .our_awards
    .award_images {
    gap: 50px;
  }
  /* media querry for How we work section */

  .how_we_work_section .work_container {
    flex-direction: column;
    align-items: center;
  }

  .how_we_work_section .work_container .work_content_wrapper {
    width: 100%;
    /* border: 2px solid ; */
  }
}

@media (max-width: 820px) {
  .how_we_work_section .work_container .work_img_section .reach_us .phone a {
    font-size: 1.3rem;
  }
  .how_we_work_section .work_container .work_img_section .reach_us .phone h6 {
    font-size: 1.3rem;
  }

  .how_we_work_section .work_container .work_img_section .reach_us .email a {
    height: 43px;
    width: 108px;
  }
}

@media (max-width: 952px) {
  .about_page_section
    .main_wrapper
    .about_page_container
    .about_page_content
    .cont2
    img {
    width: 70%;
  }
  .vacant {
    /* height: 0vh; */
    background-color: white;
  }
  .our_story_wrapper {
    padding-top: 0px;
     
   }
}

@media (max-width: 896px) {
  .about_page_section .main_wrapper .about_page_container {
    display: flex;
    flex-direction: column;
    position: static;
  }
  .about_page_section .main_wrapper .about_page_container .about_page_content {
    width: 100%;
    margin-top: 30px;
    flex-direction: column;
  }
  .about_page_section
    .main_wrapper
    .about_page_container
    .about_page_content
    .cont2
    img {
    width: 100%;
  }

  .about_page_section .main_wrapper .about_page_container .image {
    display: none;
    width: 100%;
    margin-top: 60px;
    /* border: 1px solid white; */
    justify-content: space-between;
    flex-direction: row;
    gap: 60px;
    /* background-color: white; */
  }
  .about_page_section
    .main_wrapper
    .about_page_container
    .about_page_content
    .cont2 {
    margin-bottom: 60px;
  }
  .about_page_section .main_wrapper .about_page_container .image img {
    width: 50%;
    height: 250px;
  }
  .vacant {
    height: 40vh;
  }
  .vacant .temp_image {
    visibility: visible;
    height: 40vh;
  }
}

@media (max-width: 768px) {
  .our_office_section .our_office .office {
    grid-template-columns: repeat(2, 1fr);
  }
  .how_we_work_section .work_container .work_img_section {
    max-height: fit-content;
  }
  .how_we_work_section .work_container .work_img_section{
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 600px) {
  .our_office_section .our_office .office {
    grid-template-columns: repeat(1, 1fr);
  }
  .our_office_section .our_office .office .snip0015 {
    width: 94%;
  }
  .our_office_section .our_office .office .snip0015 img {
    width: 100%;
    margin: 0;
    /* border: 2px solid red; */
  }

  .our_office_section .our_office .office .snip0015 figcaption h2 {
    font-size: 4.3rem;
  }
  .our_office_section .our_office .office .snip0015 figcaption p {
    font-size: 3.5rem;
  }
}


@media (max-width:465px){
  .trial_section .subscription_wrapper{
    padding: 20px 0;
  }

  .trial_section .subscription_wrapper .subscribe_cont{
    margin-bottom: 20px;
    width: 100%;

  }
  /* .trial_section .subscription_wrapper .subscribe_feature_list ul {
    grid-template-columns: 1fr ;
  } */
  trial_section .subscription_wrapper .subscribe_feature_list ul li{
    font-size: 5rem;
  }
}