/* about page css starts here */
.about_page_section {
  background-color: #0b163f;
  margin-top: -2px;
}
.about_page_section .main_wrapper {
  width: 90%;
  margin: 0 auto;
}

.about_page_section .main_wrapper .about_page_container {
  /* background-color: rgb(0, 202, 13); */
  display: flex;
  position: relative;
  top: 100px;
}
.about_page_section .main_wrapper .about_page_container .image {
  /* background-color: rgb(197, 204, 223); */
  display: flex;
  width: 50%;
  align-items: center;
  gap: 40px;
  flex-direction: column;
}
.about_page_section .main_wrapper .about_page_container .image img {
  width: 80%;
}
.about_page_section .main_wrapper .about_page_container .about_page_content {
  /* background-color: rgb(209, 22, 22); */
  display: flex;
  width: 50%;
  flex-direction: column;
}

.about_page_section
  .main_wrapper
  .about_page_container
  .about_page_content
  .cont1 {
  /* border: 1px solid white; */
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 60px;
}
.about_page_section
  .main_wrapper
  .about_page_container
  .about_page_content
  .cont1
  h1 {
  font-size: 3rem;
  color: white;
  font-family: "Poppins", sans-serif;
}
.about_page_section
  .main_wrapper
  .about_page_container
  .about_page_content
  .cont1
  p {
  font-size: 2rem;
  color: white;
  font-family: "Open Sans", sans-serif;
}
.about_page_section
  .main_wrapper
  .about_page_container
  .about_page_content
  .cont1
  .btns {
  /* border: 4px solid white; */
  display: flex;
  gap: 40px;
}
.about_page_section
  .main_wrapper
  .about_page_container
  .about-page_content
  .cont1
  .btns
  button {
  width: 34%;
  height: 50px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: 0.5s;
}
.about_page_section
  .main_wrapper
  .about_page_container
  .about_page_content
  .cont1
  .btns
  button:hover {
  background-color: rgb(57, 57, 188) !important;
  color: white !important;
}

.about_page_section
  .main_wrapper
  .about_page_container
  .about_page_content
  .cont1
  .btns >
  .link {
  background-color: rgb(68, 68, 248);
  color: #ceddff;
  display: flex;
  align-items: center;
  justify-content:center ;
  font-size: 1.5rem;
  width: 150px;
  height: 50px;
  border-radius: 5px;
  border: none;
  transition: 0.6s;
}

.about_page_section
  .main_wrapper
  .about_page_container
  .about_page_content
  .cont1
  .btns >
  .link:hover{
    background-color:  rgb(16, 16, 235);
  }

.about_page_section
  .main_wrapper
  .about_page_container
  .about_page_content
  .cont2
  img {
  /* border: 1px solid black; */
  width: 80%;
}

.vacant {
  height: 20vh;
  background-color: white;
}
.vacant .temp_image {
  visibility: hidden;
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
}
.vacant .temp_image img {
  /* display: none; */
  width: 46%;
  /* height: 296px; */
  height: 46%;
}

/* Office section */

.our_office_section {
  margin-bottom: 50px;
}
.our_office_section .our_office {
  width: 90%;
  margin: 0 auto;
}

.our_office_section .our_office .office {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}
.our_office_section .our_office .our_office_heading {
  height: 150px;
  text-align: center;
}
.our_office_section .our_office .our_office_heading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
 
}

.our_office_section .our_office .our_office_heading h3 {
  font-size: 2.5rem;
  color: #175cff;
  font-family: "Roboto", sans-serif;
}
.our_office_section .our_office .our_office_heading h2 {
  font-size: 3.8rem;
  color: #071c4d;
  font-family: "Roboto", sans-serif;
}

figure.snip0015 {
  font-family: "Raleway", Arial, sans-serif;
  color: #fff;
  position: relative;
  overflow: hidden;
  margin: 10px;
  max-height: 350px;
  width: 100%;
  background: #000000;
  text-align: center;
}
figure.snip0015 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
figure.snip0015 img {
  opacity: 1;
  width: 100%;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}
figure.snip0015 figcaption {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 3em 3em;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
figure.snip0015 figcaption::before {
  position: absolute;
  top: 50%;
  right: 30px;
  bottom: 50%;
  left: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
  content: "";
  opacity: 0;
  background-color: #ffffff;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;

  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
figure.snip0015 h2,
figure.snip0015 p {
  margin: 0 0 5px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, -webkit-transform 0.35s, -moz-transform 0.35s,
    -o-transform 0.35s, transform 0.35s;
    font-size: 2rem;
}
figure.snip0015 h2 {
  word-spacing: -0.15em;
  font-weight: 300;
  text-transform: uppercase;
  -webkit-transform: translate3d(0%, 50%, 0);
  transform: translate3d(0%, 50%, 0);
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
figure.snip0015 h2 span {
  font-weight: 800;
}
figure.snip0015 p {
  font-weight: 200;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
figure.snip0015 a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  color: #ffffff;
}
figure.snip0015:hover img {
  opacity: 0.35;
}
figure.snip0015:hover figcaption h2 {
  opacity: 1;
  -webkit-transform: translate3d(0%, 0%, 0);
  transform: translate3d(0%, 0%, 0);
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
figure.snip0015:hover figcaption p {
  opacity: 0.9;
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
figure.snip0015:hover figcaption::before {
  background: rgba(255, 255, 255, 0);
  top: 30px;
  bottom: 30px;
  opacity: 1;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

/* media querry */

@media (max-width: 952px) {
  .about_page_section
    .main_wrapper
    .about_page_container
    .about_page_content
    .cont2
    img {
    width: 70%;
  }
  .vacant {
    /* height: 0vh; */
    background-color: white;
  }
}

@media (max-width: 896px) {
  .about_page_section .main_wrapper .about_page_container {
    display: flex;
    flex-direction: column;
    position: static;
  }
  .about_page_section .main_wrapper .about_page_container .about_page_content {
    width: 100%;
    /* border: 1px solid white; */
    margin-top: 30px;
    flex-direction: column;
  }
  .about_page_section
    .main_wrapper
    .about_page_container
    .about_page_content
    .cont2
    img {
    width: 100%;
  }

  .about_page_section .main_wrapper .about_page_container .image {
    display: none;
    width: 100%;
    margin-top: 60px;
    /* border: 1px solid white; */
    justify-content: space-between;
    flex-direction: row;
    gap: 60px;
    /* background-color: white; */
  }
  .about_page_section
    .main_wrapper
    .about_page_container
    .about_page_content
    .cont2 {
    margin-bottom: 60px;
  }
  .about_page_section .main_wrapper .about_page_container .image img {
    width: 50%;
    height: 250px;
  }
  .vacant {
    height: 40vh;
  }
  .vacant .temp_image {
    visibility: visible;
    height: 40vh;
  }

  .our_office_section .our_office .office {
    grid-template-columns: repeat(2, 1fr);
  }
  .our_office_section .our_office .office .snip0015 figcaption h2 {
    font-size: 4.3rem;
  }
  .our_office_section .our_office .office .snip0015 figcaption p {
    font-size: 3.5rem;
  }
}

@media (max-width: 600px) {
  .our_office_section {
    margin-top: -80px;
  }
  .our_office_section .our_office .office {
    grid-template-columns: repeat(1, 1fr);
  }
  .our_office_section .our_office .office .snip0015 {
    width: 94%;
  }
  .our_office_section .our_office .office .snip0015 img {
    width: 100%;
    margin: 0;
    /* border: 2px solid red; */
  }

  .our_office_section .our_office .office .snip0015 figcaption h2 {
    font-size: 4.3rem;
  }
  .our_office_section .our_office .office .snip0015 figcaption p {
    font-size: 3.5rem;
  }
}
