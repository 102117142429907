.why_us_container {
  background-color: #fffbfb;
  margin-bottom: 60px;
}
.why_us_container .content_wrapper {
  width: 90%;
  margin: 0 auto;
}
.why_us_container .content_wrapper .heading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 35px 0 40px 0;
}

.why_us_container .content_wrapper .heading h3 {
  font-size: 3rem;
  font-family: "Open Sans", sans-serif;
  color: #175cff;
}
.why_us_container .content_wrapper .heading h4 {
  font-size: 3.2rem;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: #071c4d;
}

.why_us_container .content_wrapper .content_section {
  display: flex;
  /* background-color: blue; */
}
.why_us_container .content_wrapper .content_section .images_section {
  width: 50%;
  display: flex;
  flex-direction: column;
  column-gap: 10px;
  row-gap: 50px;
  /* background-color: rgb(192, 234, 25); */
}
.why_us_container .content_wrapper .content_section .images_section img {
  width: 90%;
  height: auto;
}
.why_us_container .content_wrapper .content_section .text_content_cont {
  width: 50%;
  display: flex;
  gap: 15px;
  /* background-color: rgb(7, 166, 95); */
}

.why_us_container
  .content_wrapper
  .content_section
  .text_content_cont
  .tree_structure {
  width: 100px;
  padding: 10px;
}

.why_us_container
  .content_wrapper
  .content_section
  .text_content_cont
  .tree_structure
  .timeline_icon {
  height: 70px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #e4d4d4;
}

.why_us_container
  .content_wrapper
  .content_section
  .text_content_cont
  .tree_structure
  .timeline_icon
  .ic {
  font-size: 3.2rem;
  color: #e4d4d4;
}

.why_us_container
  .content_wrapper
  .content_section
  .text_content_cont
  .tree_structure
  .timeline_icon
  .ic:hover {
  font-size: 3.2rem;
  color: #0d6efd;
}

.why_us_container
  .content_wrapper
  .content_section
  .text_content_cont
  .tree_structure
  .line {
  height: 71px;
  border-right: 2px dotted;
  margin: 0 auto;
  width: 0px;
  color: #e4d4d4;
}

.why_us_container
  .content_wrapper
  .content_section
  .text_content_cont
  .text_cont
  h5 {
  font-size: 1.7rem;
  font-family: "Poppins", sans-serif;
  margin: 6px 0;
  color: #071c4d;
}
.why_us_container
  .content_wrapper
  .content_section
  .text_content_cont
  .text_cont
  p {
  font-size: 1.6rem;
  font-family:  'Poppins', sans-serif;
  color: #575757;
}

.why_us_container
  .content_wrapper
  .content_section
  .text_content_cont
  .text_cont
  div {
    margin-bottom: 40px;
}

/* media querry */
@media (max-width: 1110px) {
  .why_us_container
    .content_wrapper
    .content_section
    .text_content_cont
    .text_cont
    h2 {
    font-size: 1.6rem;
    font-family: "Poppins", sans-serif;
  }
  .why_us_container
    .content_wrapper
    .content_section
    .text_content_cont
    .text_cont
    p {
    font-size: 1.6rem;
    font-family: "Poppins", sans-serif;
  }
}
@media (max-width: 805px) {
  .why_us_container .content_wrapper .content_section {
    flex-direction: column-reverse;
  }
  .why_us_container .content_wrapper .content_section .text_content_cont {
    width: 100%;
  }
  .why_us_container
    .content_wrapper
    .content_section
    .text_content_cont
    .tree_structure
    .line {
    height: 45px;
  }
}
@media (max-width: 794px) {
  .why_us_container .content_wrapper .content_section .images_section {
    width: 100%;
    align-items: center;
  }
}
@media (max-width: 651px) {
  .why_us_container
    .content_wrapper
    .content_section
    .text_content_cont
    .text_cont
    h2 {
    font-size: 1.6rem;
    font-family: "Open Sans", sans-serif;
  }


  .why_us_container .content_wrapper .heading h2 {
    font-size: 2.8rem;
  }
  .why_us_container .content_wrapper .heading h3 {
    font-size: 3rem;
  }

  .why_us_container
    .content_wrapper
    .content_section
    .text_content_cont
    .tree_structure
    .line {
    height: 65px;
  }
}


@media (max-width: 350px) {
  .why_us_container
    .content_wrapper
    .content_section
    .text_content_cont
    .text_cont
    h2 {
    font-size: 1.6rem;
    font-family: "Poppins", sans-serif;
  }
  .why_us_container
    .content_wrapper
    .content_section
    .text_content_cont
    .text_cont
    p {
    font-size: 2rem;
    font-family: "Poppins", sans-serif;
  }
}

@media (max-width:486px) {
  .why_us_container
    .content_wrapper
    .content_section
    .text_content_cont
    .tree_structure
    .line{
      height: 70px;
    }
}

@media (max-width:400px) {
  .why_us_container
    .content_wrapper
    .content_section
    .text_content_cont
    .tree_structure
    .line{
      height: 100px;
    }
}

