/* page header starts */
.page_header_container {
  background-color: #0b163f;
  padding: 60px 0;
  overflow: hidden;
}

/* we can also show this is in background */

.page_header_container {
  background-image: url("../../img/blur-banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: center;
}

.page_header_container .page_header {
  width: 90%;
  margin: 0 auto;
  position: relative;
}

.page_header_container .page_header .main_heading {
  width: 70%;
  position: relative;
}

.page_header_container .page_header .main_heading h1 {
  font-size: 3.1rem;
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
}

.page_header_container .page_header .main_heading p.lead {
  font-size: 1.6rem;
  color: #d6d5e1;

  text-align: justify;
  font-family: "Poppins", sans-serif;
}
.page_header_container .page_header .vacant {
  height: 100px;
  background-color: #071c4d;
  width: 230px;
  position: absolute;
  right: 23px;
  bottom: -74px;
  /* z-index: -1; */
}

/* top or first feature section */
.social_media_feature_section {
  background-color: #fffbfb;
  padding: 30px 0;
}

.social_media_feature_section .social_media_feature_wrapper {
  width: 90%;
  margin: 0 auto;
}

.social_media_feature_section
  .social_media_feature_wrapper
  .social_media_feature_content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.social_media_feature_section
  .social_media_feature_wrapper
  .social_media_feature_content
  .left {
  display: flex;

  align-items: center;
  justify-content: center;
}

.social_media_feature_section
  .social_media_feature_wrapper
  .social_media_feature_content
  .left
  img {
  width: 70%;
}

.social_media_feature_section
  .social_media_feature_wrapper
  .social_media_feature_content
  .right {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.social_media_feature_section
  .social_media_feature_wrapper
  .social_media_feature_content
  .right
  ul
  li {
  list-style-type: disc;
  list-style-position: outside;
  font-size: 1.6rem;
  margin: 10px 0;
  color: #575757;
  line-height: 1.7;
  font-family: "Poppins", sans-serif;
}

.social_media_feature_section
  .social_media_feature_wrapper
  .social_media_feature_content
  .right
  ul
  li
  span {
  color: #575757;
  font-family: "Roboto", sans-serif;
}

.social_media_feature_section
  .social_media_feature_wrapper
  .social_media_feature_content
  .right
  h2 {
  font-size: 3rem;
  font-family: "Roboto", sans-serif;
  color: #071c4d;
  margin-left: -18px;
  font-weight: 900;
}

/* second feature section */
.how_social_media_feature_section {
  background-color: #fffbfb;
  padding: 30px 0;
}

.how_social_media_feature_section .how_social_media_feature_wrapper {
  width: 90%;
  margin: 0 auto;
}

.how_social_media_feature_section
  .how_social_media_feature_wrapper
  .how_social_media_feature_content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.how_social_media_feature_section
  .how_social_media_feature_wrapper
  .how_social_media_feature_content
  .left {
  display: flex;
  background-image: url(../../img/shape/dot-big-square.png);
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;

  background-position: 347px 214px;
}

.how_social_media_feature_section
  .how_social_media_feature_wrapper
  .how_social_media_feature_content
  .left
  img {
    padding: 15px 0;
  width: 70%;
}

.how_social_media_feature_section
  .how_social_media_feature_wrapper
  .how_social_media_feature_content
  .right {
  padding: 20px;
  display: flex;
  flex-direction: column;
  order: -1;
}

.how_social_media_feature_section
  .how_social_media_feature_wrapper
  .how_social_media_feature_content
  .right
  p {
  font-size: 1.6rem;

  color: #575757;
  font-weight: 0;
  font-family: "Poppins", sans-serif;
  text-align: justify;
}

.how_social_media_feature_section
  .how_social_media_feature_wrapper
  .how_social_media_feature_content
  .right
  ul
  li {
  list-style-type: disc;
  list-style-position: outside;
  font-size: 1.6rem;
  margin: 10px 0;
  color: #575757;
  font-family: "Poppins", sans-serif;
}

.our_services {
  font-size: 1.5rem;
  display: inline-block;
  font-family: "Poppins", sans-serif;

  margin-top: 20px;
  color: #575757;
}

.how_social_media_feature_section
  .how_social_media_feature_wrapper
  .how_social_media_feature_content
  .right
  h2 {
  font-size: 3.5rem;
  font-family: "Roboto", sans-serif;
  color: #071c4d;

}

/*social_media Methodology Section starts here */
.social_media_strategy_wrapper {
  padding: 30px 0;
}
.social_media_strategy_wrapper .social_media_strategy_content_wrapper {
  width: 90%;
  margin: 0 auto;
}

.social_media_strategy_wrapper
  .social_media_strategy_content_wrapper
  .social_media_strategy_heading {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}

.social_media_strategy_wrapper
  .social_media_strategy_content_wrapper
  .social_media_strategy_heading
  h2 {
  font-size: 3.3rem;
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  text-align: center;
  padding-bottom: 10px;
}
.social_media_strategy_wrapper
  .social_media_strategy_content_wrapper
  .social_media_strategy_heading
  p {
  font-size: 1.6rem;
  text-align: center;
  color: #575757;
  font-weight: 900;
  font-family: "Open Sans", sans-serif;
}
.social_media_strategy_wrapper
  .social_media_strategy_content_wrapper
  .social_media_strategy_content_cont {
  padding: 30px;
  transition: 0.5s all;
  border: 2px solid #57575736;
}
.social_media_strategy_wrapper
  .social_media_strategy_content_wrapper
  .social_media_strategy_content_cont:hover {
  border: 2px solid rgb(75, 75, 253);
}
.social_media_strategy_wrapper
  .social_media_strategy_content_wrapper
  .social_media_strategy_content_cont
  h2 {
  font-size: 1.8rem;
  color: #071c4d;
  font-family: "Poppins", sans-serif;
}

.social_media_strategy_wrapper
  .social_media_strategy_content_wrapper
  .social_media_strategy_content_cont
  p {
  font-size: 1.6rem;

  color: #575757;
  padding-bottom: 12px;
  text-align: justify;
  font-family: "Poppins", sans-serif;
}

/* media querries */
@media (max-width: 1132px) {
  html {
    font-size: 10px;
  }
  .social_media_feature_section .social_media_feature_wrapper h2 {
    padding-left: 20px;
  }
  .social_media_feature_section
    .social_media_feature_wrapper
    .social_media_feature_content {
    grid-template-columns: 1fr;
  }
  .social_media_feature_section
    .social_media_feature_wrapper
    .social_media_feature_content
    .right {
    order: -1;
  }

  .how_social_media_feature_section
    .how_social_media_feature_wrapper
    .how_social_media_feature_content {
    grid-template-columns: 1fr;
  }
  .how_social_media_feature_section
    .how_social_media_feature_wrapper
    .how_social_media_feature_content
    .right {
    order: -1;
  }
}

@media (max-width: 858px) {
  .page_header_container .page_header .main_heading {
    width: 100%;
    position: relative;
  }
}

@media (max-width: 933px) {
  .how_social_media_feature_section
    .how_social_media_feature_wrapper
    .how_social_media_feature_content
    .left {
    background-image: none;
  }
  .social_media_strategy_wrapper
    .social_media_strategy_content_wrapper
    .social_media_strategy_heading
    h2 {
    font-size: 2.6rem;
  }
}

@media (max-width: 768px) {
.social_media_feature_section {
  padding: 0;
}
.page_header_container .page_header .main_heading h1{
  margin-bottom: 10px;
}
  .how_social_media_feature_section
    .how_social_media_feature_wrapper
    .how_social_media_feature_heading
    h2 {
    font-size: 3rem;
  }
  .social_media_feature_section
    .social_media_feature_wrapper
    .social_media_feature_content
    .left {
    background-image: none;
  }
  .social_media_features
    .social_media_feature_wrapper
    .social_media_feature_cards {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 568px) {
  .how_social_media_feature_section
  .how_social_media_feature_wrapper
  .how_social_media_feature_content
  .right{
    padding: 0;
  }
}
