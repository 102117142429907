.cont {
  position: fixed;
  /* height: 5vh;
  width: 10%; */
  z-index: 99;
  right: 20px;
  bottom: 100px;
}
.icon{
    font-size: 3.2rem;
    color: white;
    /* padding-left: 6px; */
}
.cont .scrollBtn {
  height: 40px;
  width: 40px;

  cursor: pointer;
  display: none;
  position: absolute;
  right: 2px;
  bottom: -26px;

  appearance: none;
  background-color: transparent;
  outline: none;
 border-radius: 50%;
  color: #000000;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
    background-color:  #010e2b;
  font-size: 1.6rem;
  font-weight: 400;

  text-decoration: none;
  text-align: center;
  /* text-transform: uppercase; */
  font-family: "Montserrat", sans-serif;

  animation: color 5s linear infinite alternate-reverse;
}

@keyframes color {
    
    50%{

        transform:translateY(-10px)
    }
    100%{
     transform:translateY(10px)
    }
}
.scrollBtn {
  border: none;

  /* box-shadow: 0 0 40px 40px #3498db inset, 0 0 0 0 #3498db; */
  -webkit-transition: all 150ms ease-in-out;
  transition: all 0.3s ease-in-out;
}
.scrollBtn:hover {
    transform: translateY(-8px);
  box-shadow: 0 0 10px 0 #3498db inset, 0 0 10px 4px #3498db;
}
