.award_container {
  background-color: #fffbfb;
}
.award_container .award_section {
  width: 90%;
  margin: 0 auto;
  padding: 35px 0;
  margin-bottom: 50px;
}

.award_container .award_section .main_heading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.award_container .award_section .main_heading h3 {
  font-size: 3rem;
  color: #071c4d;
}

.award_container .award_section .award_item_cont {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
}
.award_container .award_section .award_item_cont .award_item {
  height: 200px;
  padding: 0 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 15px;
  background-color: rgb(255 255 255);
  transition: transform 0.5s;
  box-shadow: 10px 10px 20px #c7c7c7, -10px -10px 20px #f9f9f9;
}

.award_container .award_section .award_item_cont .award_item img{
  height: 30px;
  width : 30px;
}
.award_container .award_section .award_item_cont .award_item h6 {
  font-size: 1.8rem;
  margin: 12px 0;
  color: #071c4d;
  font-family: "Open Sans", sans-serif;
}
.award_container .award_section .award_item_cont .award_item p {
  font-size: 1.5rem;
  text-align: center;
}

.award_container .award_section .award_item_cont .award_item:hover {
  transform: translateY(-8px);
}

/* media querry */
@media (max-width: 1228px) {
  .award_container .award_section .award_item_cont {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 20px;
  }
}
@media (max-width: 944px) {
  .award_container .award_section .award_item_cont {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 20px;
  }
}
@media (max-width: 744px) {
  /* .award_container .award_section .award_item_cont {
    margin-top: 60px;
  } */
}

@media (max-width: 642px) {
  .award_container .award_section .award_item_cont {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  .award_container .award_section .main_heading h2 {
    font-size: 2.7rem;
  }
}
@media (max-width: 390px) {
  .award_container .award_section .main_heading h2 {
    font-size: 2.2rem;
    text-align: center;
  }
}
