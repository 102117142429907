@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body{
	line-height: 1.5;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	overflow-x: hidden;
}
*{
	margin:0;
	box-sizing: border-box;
}
html{
	font-size: 10px;
}
:before,:after{
	box-sizing: border-box;
}
/* header */


	
.header{
	display: block;
	width: 100%;
	top: 0;
	position: sticky;
	z-index: 9999;
	padding:3px 15px;
	transition: .3s linear;
	background-color: white;
}


.default_nav_color{
	background-color: white;
}
.scrolled_nav_color{
	background: linear-gradient(90deg, #021048, #1e38a3) ;
	
}

.down-arrow{
	padding-top: 3px;
}

.container{
	max-width: 1200px;
	margin:auto;
}

.row{
	display: flex;
	flex-wrap: wrap;
}
.v-center{
	align-items: center;
	justify-content: space-evenly;
	margin: 8px 0;
}
ul{
	list-style: none;
	margin:0;
	padding:0;
  width: 100%;

}
a{
	text-decoration: none !important;
	/* margin: 20px; */
  font-size: 1.6rem;
}
.menu-main > li .sub-menu .company-section  .link{
	display: flex;
	gap: 15px;

	align-items: center;
	/* border: 2px solid blueviolet; */
	width: 100%;
	margin: 0;
  padding: 10px 0;
}

.menu-main{

  width: fit-content;
  margin-left: 100px;
}

.menu-main > li .company-profile{
	/* border: 1px solid red; */
	min-width: 300px;
}
.menu-main > li .company-profile h2{
	font-size: 2rem;
}

.menu-main > li .sub-menu .company-section  {
	/* border: 2px solid red; */
	padding: 0;
	margin: 0;
	display: flex;
	padding: 0 ;
	margin: 0;
	width: 100%;
}
.menu-main > li .sub-menu .company-section  .link span{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	background-color: #0a65ee;
	color: white;
	border-radius: 4px;
	font-size: 1.6rem;
}

.menu-main > li.menu-item-has-children .sub-menu .list-item ul li .link{
  display: flex !important;
  /* border: 2px solid rgb(207, 17, 4); */
    width: 100%;
    gap: 15px;
}
.menu-main > li.menu-item-has-children .sub-menu .list-item ul li .link h4{
  font-size: 1.8rem;
  padding: 10px 0;
    font-size: 15px;
    color: #555555;
    transition: color 0.3s ease;
    font-weight: 600;
}
.menu-main > li.menu-item-has-children .sub-menu .list-item ul li .link span{

 font-size: 1.8rem;
 display: flex;
 align-items: center;
}


.header .item-left{
	flex:0 0 17%;
}
.header .logo img{
	width: 180px;
	height: auto;
}
.header .logo a{
	font-size: 30px;
	color:#000000;
	font-weight: 700;
	text-decoration: none;
    display: flex;
    align-items: center;
}
.header .item-center{
	flex:0 0 66%;
}
.header .item-right{
	flex:0 0 17%;
	display: flex;
	justify-content: flex-end;
}
.header .item-right .link{ 
     text-decoration: none;
     font-size: 16px;
     color:#555555;
     display: inline-block;
     margin-left: 10px;
     transition: color 0.3s ease;
}
.header .menu > ul > li{
	display: inline-block;
	line-height: 50px;
	margin-left: 25px;
}
.header .menu > ul > li > .link{
	font-size: 15px;
	font-weight: 500;
	color:#000000;
	position: relative;
	text-transform: capitalize;
	transition: color 0.3s ease;
}
.header .menu > ul > li .sub-menu{
	position: absolute;
	z-index: 500;
	background-color:#ffffff;
	box-shadow: -2px 2px 70px -25px rgba(0,0,0,0.3); 
	padding: 20px 30px;
	transition: all 0.5s ease;
	margin-top:25px;
	opacity:0;
	visibility: hidden;
}
@media(min-width: 992px){
.header .menu > ul > li.menu-item-has-children:hover .sub-menu{
	margin-top: 0;
	visibility: visible;
	opacity: 1;
}
}

.header .menu > ul > li .sub-menu > ul > li{
	line-height: 1;
}
.header .menu > ul > li .sub-menu > ul > li > .link{
	display: inline-block;
	padding: 10px 0;
	font-size: 15px;
	color: #555555;
	transition: color 0.3s ease;
	text-decoration: none;
	text-transform: capitalize;
}
.header .menu > ul > li .single-column-menu{
	min-width: 280px;
	max-width: 350px;
}
.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li{
   line-height: 1;
   display: block; 
}
.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > .link {
	padding:5px 10px;
	display: inline-block;
	font-size: 15px;
	color:#555555;
	transition: 0.5s ease;
  border-radius: 5px;
}
.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > .link:hover {
background-color: rgba(226, 209, 209, 0.74);
}


.header .menu > ul > li .sub-menu.mega-menu{ 	
    left: 50%;
    transform: translateX(-50%);
    /* box-shadow: 10px 10px 20px #c7c7c7, -10px -10px 20px #f9f9f9; */
    border-radius: 6px;
}
.header .menu > ul > li .sub-menu.home_sub_menu{ 	
    left: 37%;
    transform: translateX(-50%);
    /* box-shadow: 10px 10px 20px #c7c7c7, -10px -10px 20px #f9f9f9; */
    border-radius: 6px;
}



.header .menu > ul > li .sub-menu.mega-menu-column-4{
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap;
  padding:20px 15px;
  width: 100%;
  justify-content: space-around;
}
.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item{
  padding:0 15px; 
}
.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item .title{
	font-size: 16px;
	color:#ea4636;
	font-weight: 500;
	line-height: 1;
	padding:10px 0;
}
.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item.text-center .title{
	text-align: center;
}

.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > .link:hover,
.header .menu > ul > li .sub-menu > ul > li > a:hover,
.header .item-right a:hover,
.header .menu > ul > li:hover > a{
	color:#ea4636;
}
/* banner section */

.mobile-menu-head,
.mobile-menu-trigger{
	display: none;
}










/*responsive*/
@media(max-width: 1116px){
  .menu-main{
   
    margin-left: 0px;
    width: 100%;
  }

  .header .logo img {
    width: 165px;
	height: auto;
    margin-left: -25px;
}

	.header .item-center{
		order:3;
		flex:0 0 100%;
	}
	.header .item-left,
	.header .item-right{
		flex:0 0 auto;
	}
  .menu-main > li .company-profile h2 {
    margin-bottom: 10px;
}


	.v-center{
		justify-content: space-between;
	}
	.header .mobile-menu-trigger{
		display: flex;
		height: 30px;
		width: 30px;
		margin-left: 15px;
		cursor: pointer;
		align-items: center;
		justify-content: center;
	}
	.header .mobile-menu-trigger span{
		display: block;
		height: 2px;
		background-color: #333333;
		width: 24px;
		position: relative;
	}
	.header .mobile-menu-trigger span:before,
	.header .mobile-menu-trigger span:after{
		content: '';
		position: absolute;
		left:0;
		width: 100%;
		height: 100%;
		background-color: #333333;
	}
	.header .mobile-menu-trigger span:before{
		top:-6px;
	}
	.header .mobile-menu-trigger span:after{
		top:6px;
	}
	.header .item-right{
		align-items: center;
	}

	.header .menu{
		position: fixed;
		width: 320px;
		background-color:#ffffff;
		left:0;
		top:0;
		height: 100%;
		overflow: hidden;
		transform: translate(-100%);
		transition: all 0.5s ease;
		z-index: 1099;
	}
	.header .menu.active{
	   transform: translate(0%);	
	}
	.header .menu > ul > li{
		line-height: 1;
		margin:0;
		display: block;
	}
	.header .menu > ul > li > .link{
		line-height: 50px;
		height: 50px;
		padding:0 50px 0 15px;
		display: block;
		border-bottom: 1px solid rgba(0,0,0,0.1);
	}
	.header .menu > ul > li > .link i{
		position: absolute;
		height: 50px;
		width: 50px;
		top:0;
		right: 0;
		text-align: center;
		line-height: 50px;
		transform: rotate(-90deg);
	}
	.header .menu .mobile-menu-head{
		display: flex;
		height: 50px;
		border-bottom: 1px solid rgba(0,0,0,0.1);
		justify-content: space-between;
		align-items: center;
		position: relative;
		z-index: 501;
		position: sticky;
		background-color: #ffffff;
		top:0;
	}
	.header .menu .mobile-menu-head .go-back{
		height: 50px;
		width: 50px;
		border-right: 1px solid rgba(0,0,0,0.1);
		cursor: pointer;
		line-height: 50px;
		text-align: center;
		color:#000000;
		font-size: 16px;
		display: none;
	}
	.header .menu .mobile-menu-head.active .go-back{
		display: block;
	}
	.header .menu .mobile-menu-head .current-menu-title{
		font-size: 15px;
		font-weight: 500;
		color:#000000;
	}
	.header .menu .mobile-menu-head .mobile-menu-close{
	    height: 50px;
		width: 50px;
		border-left: 1px solid rgba(0,0,0,0.1);
		cursor: pointer;
		line-height: 50px;
		text-align: center;
		color:#000000;	
		font-size: 25px;
	}
	.header .menu .menu-main{
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
	}
	.header .menu > ul > li .sub-menu.mega-menu,
	.header .menu > ul > li .sub-menu{
		visibility: visible;
		opacity: 1;
		position: absolute;
		box-shadow: none;
		margin:0;
		padding:15px;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		padding-top: 65px;
		max-width: none;
		min-width: auto;
		display: none;
		transform: translateX(0%);
		overflow-y: auto;
	}
.header .menu > ul > li .sub-menu.active{
	display: block;
}
@keyframes slideLeft{
	0%{
		opacity:0;
		transform: translateX(100%);
	}
	100%{
	    opacity:1;
		transform: translateX(0%);	
	}
}
@keyframes slideRight{
	0%{
		opacity:1;
		transform: translateX(0%);
	}
	100%{
	    opacity:0;
		transform: translateX(100%);	
	}
}
	.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item img{
		margin-top:0;
	}
	.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item.text-center .title{
		margin-bottom: 20px;
	}
	.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item.text-center:last-child .title{
		margin-bottom:0px;
	}
	.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item{
		flex: 0 0 100%;
        padding: 0px;
	}
	.header .menu > ul > li .sub-menu > ul > li > a,
	.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > .link{
		display: block;
	}
	.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul {
		margin-bottom: 15px;
	}
	.menu-overlay{
		position: fixed;
		background-color: rgba(0,0,0,0.5);
		left:0;
		top:0;
		width: 100%;
		height: 100%;
		z-index: 1098;
		visibility: hidden;
		opacity:0;
		transition: all 0.5s ease;
	}
	.menu-overlay.active{
	  visibility: visible;
	  opacity:1;	
	}
}