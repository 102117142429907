/* page header starts */
.page_header_container {
  background-color: #0b163f;
  padding: 60px 0;
  overflow: hidden;
}

.page_header_container {
  background-image: url("../../img/banner.png");

  background-repeat: no-repeat;
  background-size: cover;
}

.page_header_container .page_header {
  width: 90%;
  margin: 0 auto;
  position: relative;
}

.page_header_container .page_header .main_heading {
  width: 60%;
}

.page_header_container .page_header .main_heading h1 {
  font-size: 3.1rem;
  color: white;
  font-family: "Roboto", sans-serif;
  margin-bottom: 10px;
  font-weight: 900;
}
.page_header_container .page_header .main_heading h3 {
  font-size: 2.1rem;
  color: white;
  font-family: "Roboto", sans-serif;
  margin-bottom: 17px;
  font-weight: 900;
}

.page_header_container .page_header .main_heading p.lead {
  font-size: 1.6rem;
  color: #d6d5e1;
  text-align: justify;
  line-height: 1.7;
  font-family: "Poppins", sans-serif;
}

/* feature section starts here */
.web_feature_section {
  background-color: #fffbfb;
  padding: 30px 0;
}

.web_feature_section .web_feature_wrapper {
  width: 90%;
  margin: 0 auto;
}

.web_feature_section .web_feature_wrapper .web_feature_content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.web_feature_section .web_feature_wrapper .web_feature_content .left {
  display: flex;
  background-image: url(../../img/shape/dot-big-square.png);
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;

  background-position: 12px 3px;
}

.web_feature_section .web_feature_wrapper .web_feature_content .left img {
  width: 70%;
}

.web_feature_section .web_feature_wrapper .web_feature_content .right {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.web_feature_section .web_feature_wrapper .web_feature_content .right p {
  font-size: 1.6rem;
  line-height: 1.7;
  color: #575757;
  font-weight: 0;
  text-align: justify;
  font-family: "Poppins", sans-serif;
}

.web_feature_section .web_feature_wrapper .web_feature_content .right ul li {
  list-style-type: disc;
  list-style-position: outside;
  font-size: 1.6rem;
  margin: 15px 0;
  color: #575757;
  font-family: "Poppins", sans-serif;
}

.our_services {
  font-size: 1.5rem;
  display: inline-block;
  font-family: "Poppins", sans-serif;

  margin-top: 20px;
  color: #575757;
}

.web_feature_section .web_feature_wrapper .web_feature_heading h2 {
  text-align: center;
  font-size: 3rem;
  font-family: "Roboto", sans-serif;
  color: #071c4d;
}
.web_feature_section .web_feature_wrapper .web_feature_heading p {
  text-align: center;
  color: #575757;
  padding: 10px 0;
  font-size: 1.6rem;
  font-family: "Poppins", sans-serif;
}



/* why choose angular section starts here */
/*  work process section*/

.why_angular_section {
  padding: 30px 0;
}
.why_angular_container {
  background-color: #fffbfb;
}
.why_angular_container .content_wrapper {
  width: 90%;
  margin: 0 auto;
}
.why_angular_container .content_wrapper .heading {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 40px;
}

.why_angular_container .content_wrapper .angular_heading h3 {
  text-align: center;
  font-size: 3rem;
  font-family: "Open Sans", sans-serif;
  color: #175cff;
}
.why_angular_container .content_wrapper .angular_heading h2 {
  font-size: 2.5rem;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: #071c4d;
  margin: 10px 0;
}
.why_angular_container .content_wrapper .angular_heading p {
  margin: 5px 0 15px 0;
  font-size: 1.6rem;
  text-align: center;
  color: #575757;
  font-family: "Poppins", sans-serif;
}

.why_angular_container .content_wrapper .content_section {
  display: flex;
  /* background-color: blue; */
}
.why_angular_container .content_wrapper .content_section .images_section {
  width: 50%;
  display: flex;
  flex-direction: column;
  column-gap: 10px;
  row-gap: 50px;
  /* background-color: rgb(192, 234, 25); */
}
.why_angular_container .content_wrapper .content_section .images_section img {
  width: 90%;
}
.why_angular_container .content_wrapper .content_section .text_content_cont {
  width: 50%;
  display: flex;
  gap: 15px;
  /* background-color: rgb(7, 166, 95); */
}

.why_angular_container
  .content_wrapper
  .content_section
  .text_content_cont
  .tree_structure {
  width: 100px;
  padding: 10px;
}

.why_angular_container
  .content_wrapper
  .content_section
  .text_content_cont
  .tree_structure
  .timeline_icon {
  height: 70px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #e4d4d4;
}

.why_angular_container
  .content_wrapper
  .content_section
  .text_content_cont
  .tree_structure
  .timeline_icon
  .ic {
  font-size: 3.2rem;
  color: #e4d4d4;
}

.why_angular_container
  .content_wrapper
  .content_section
  .text_content_cont
  .tree_structure
  .timeline_icon
  .ic:hover {
  font-size: 3.2rem;
  color: #0d6efd;
}

.why_angular_container
  .content_wrapper
  .content_section
  .text_content_cont
  .tree_structure
  .line {
  height: 70px;
  border-right: 2px dotted;
  margin: 0 auto;
  width: 0px;
  color: #e4d4d4;
}

.why_angular_container
  .content_wrapper
  .content_section
  .text_content_cont
  .text_cont
  h2 {
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
  margin: 6px 0;
  color: #071c4d;
}
.why_angular_container
  .content_wrapper
  .content_section
  .text_content_cont
  .text_cont
  p {
  color: #575757;
  font-size: 1.6rem;
  font-family: "Poppins", sans-serif;
}

.why_angular_container
  .content_wrapper
  .content_section
  .text_content_cont
  .text_cont
  div {
  margin-bottom: 50px;
}

/*--------------- media querries ----------*/

/* media querry of work process or why us*/
@media (max-width: 1110px) {
  .why_angular_container
    .content_wrapper
    .content_section
    .text_content_cont
    .text_cont
    h2 {
    font-size: 1.6rem;
    font-family: "Poppins", sans-serif;
    margin: 6px 0;
  }
  .why_angular_container
    .content_wrapper
    .content_section
    .text_content_cont
    .text_cont
    p {
    font-size: 1.6rem;
  }
}
@media (max-width: 858px) {
  .page_header_container .page_header .main_heading {
    width: 100%;
    position: relative;
  }
}

@media (max-width: 805px) {
  .why_angular_container .content_wrapper .content_section {
    flex-direction: column-reverse;
  }
  .why_angular_container .content_wrapper .content_section .text_content_cont {
    width: 100%;
  }
  .why_angular_container
    .content_wrapper
    .content_section
    .text_content_cont
    .tree_structure
    .line {
    height: 50px;
    border-right: 2px dotted;
    margin: 0 auto;
    width: 0px;
  }
}
@media (max-width: 794px) {
  .why_angular_container .content_wrapper .content_section .images_section {
    width: 100%;
    align-items: center;
  }
}
@media (max-width: 651px) {
  .why_angular_container
    .content_wrapper
    .content_section
    .text_content_cont
    .text_cont
    h2 {
    font-size: 1.7rem;
  }

  .why_angular_container
    .content_wrapper
    .content_section
    .text_content_cont
    .tree_structure
    .line {
    height: 13%;
  }
  .why_angular_container
    .content_wrapper
    .content_section
    .text_content_cont
    .text_cont
    div {
    margin-bottom: 50px;
  }
  .why_angular_container
    .content_wrapper
    .content_section
    .text_content_cont
    .tree_structure
    .timeline_icon {
    height: 50px;
    width: 50px;
  }
}

@media (max-width: 1132px) {

  .our_features .our_feature_wrapper .our_feature_cards {
    grid-template-columns: repeat(2, 1fr);
  }

  .web_feature_section .web_feature_wrapper .web_feature_content {
    grid-template-columns: 1fr;
  }
  .web_feature_section .web_feature_wrapper .web_feature_content .right {
    order: -1;
  }
}


@media (max-width: 862px) {
  .page_header_container .page_header .main_heading h1 {
    font-size: 3.1rem;
    color: white;
    font-family: "Roboto", sans-serif;
    margin-bottom: 17px;
    font-weight: 900;
  }
}

@media (max-width: 602px) {
  .web_feature_section .web_feature_wrapper .web_feature_content .right {
    padding: 0;
  }

}
