@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Poppins&family=Roboto:wght@700&display=swap");

.marketing-container {
  background-color: #fffbfb;
  padding-top: 41px;

}

.marketing_container .marketing_section {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 20px;
}
.marketing_container .marketing_section .heading_cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0 30px 0;

}
.marketing_container .marketing_section .heading_cont h4 {
  font-size: 3rem;
  font-family: sans-serif;
  color: #071c4d;
  text-align: center;
  margin-bottom: 15px;
}
.marketing_container .marketing_section .heading_cont p {
  font-size: 1.6rem;
  font-family: "Poppins", sans-serif;
  text-align: center !important;
  color: #575757;
  margin-bottom: 20px;
}
.marketing_container .marketing_section .marketing_item_cont {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto;
  overflow: hidden;
}
.marketing_container .marketing_section .marketing_item_cont .marketing_item {
  padding: 30px;
  text-align: center;
  border-radius: 10px;
  background-color: #0b163f;
}

.marketing_container
  .marketing_section
  .marketing_item_cont
  .marketing_item
  h4 {
  color: white;
  font-size: 2.5rem;
  font-family: "Roboto", sans-serif;
  margin: 10px;
}

.marketing_container .marketing_section .marketing_item_cont .marketing_item p {
  font-size: 1.5rem;
  color: white;

  font-family: "Poppins", sans-serif;
}

/* media querry */
@media (max-width: 950px) {
  .marketing_container .marketing_section .marketing_item_cont {
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;
  }
  .marketing_container .marketing_section .heading_cont p {
    text-align: start;
  }
}

@media (max-width: 642px) {
  .marketing_container .marketing_section .marketing_item_cont {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
  }

  .marketing_container .marketing_section .heading_cont h2 {
    margin-left: -1px;
  }
  .marketing_container .marketing_section .heading_cont p {
    font-size: 1.9rem;
  }
}
