.footer_wrapper {
  /* background: linear-gradient(90deg, #021048, #1e39a3ad) !important; */
  /* height: 500px; */
  position:relative;
  display:block;
  
}

.footer_wrapper::before{
  content:"";
  background-image: url("../../img/footer.webp");
 /* opacity:0.5; */
  
  position:absolute;
  top:0;
  bottom:0;
  right:0;
  /* filter: blur(8px);
  -webkit-filter: blur(8px); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  left:0;
  width:auto;
  height:auto;
  z-index:-3;
}
.footer_wrapper .content_container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  padding: 62px 0;
  justify-content: space-around;
}
.footer_wrapper .content_container .box1 p {
  font-size: 1.5rem;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  text-align: justify;
}
.footer_wrapper .content_container .box1 img {
  margin-bottom: 12px;
  width: 50%;
}
.box .link {
  text-decoration: none;
}
.box1 {
  width: 35%;
}
.box h2 {
  font-size: 2rem;
  color: white;
  margin-bottom: 10px;
  font-family: "Open Sans", sans-serif;
}
.footer_wrapper .content_container .box2 ul li {
  font-size: 1.3rem;
  margin-bottom: 16px;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
}

.footer_wrapper .content_container .box3 p {
  font-size: 1.4rem;
  margin-bottom: 10px;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
}
.copyright {
  padding: 0 50px;
  display: flex;
  /* background: linear-gradient(90deg, #021048, #1e38a3) !important; */
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #d6d5e133;
}
.links_cont {
  width: 55%;
  display: flex;
  justify-content: space-around;
}
.links_cont h3{
  font-size: 2rem;
  color: white;
  margin-bottom: 10px;
  font-family: "Open Sans", sans-serif;
}
.copyright p {
  color: #d6d5e1;
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif;
}
.copyright .socials {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.copyright .socials span {
  padding: 10px;
  font-size: 1.2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 8px 4px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid #b9acac69;
  transition: .3s all linear;
}
.copyright .socials span:hover {
  transform: translateY(-6px);
  /* transform: scale(1.3); */
}
.copyright .socials span:hover {
  background-color: #175cff;
}
.copyright .socials .icon {
  font-size: 1.5rem;
  color: #000000;
}

/* media querry */
@media (max-width: 827px) {
  .footer_wrapper .content_container {
    flex-direction: column-reverse;
  }
  .footer_wrapper .content_container .links_cont {
    width: 100%;
    margin-bottom: 20px;
  }
  .footer_wrapper .content_container .box1 {
    width: 100%;
  }

  .copyright p {
    color: #d6d5e1;
    font-size: 1rem;
  }
}
@media (max-width:609px){
  .copyright{
    padding: 15px;
  align-items: flex-start;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width:409px){
  .copyright .socials {
    flex-wrap: wrap;
  }
}