/* page header starts */
.page_header_container {
  background-color: #0b163f;
  padding: 60px 0;
  overflow: hidden;
}

.page_header_container {
  background-image: url("../../img/blur-banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: center;
}

.page_header_container .page_header {
  width: 90%;
  margin: 0 auto;
  position: relative;
}

.page_header_container .page_header .main_heading {
  width: 70%;
}

.page_header_container .page_header .main_heading h1 {
  font-size: 3.5rem;
  color: white;
  font-family: "Roboto", sans-serif;
  margin-bottom: 10px;
  font-weight: 900;
}

/* contact options styling */
.contact_options_section {
  padding: 50px 0;
}
.contact_options_section .main_wrapper {
  width: 90%;
  margin: 0 auto;
}
.contact_options_section .main_wrapper .options {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
.contact_options_section .main_wrapper .options .option img {
  height: 50px;
  width: 50px;

  border-radius: 10px;
  box-shadow: 10px 10px 20px #c7c7c7, -10px -10px 20px #f9f9f9;
}

.contact_options_section .main_wrapper .options .option {
  display: flex;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
  box-shadow: 10px 10px 20px #c7c7c7, -10px -10px 20px #f9f9f9;
}

.contact_options_section .main_wrapper .options .option .icon {
  font-size: 3rem;
  color: #2c094d;
}
.contact_options_section .main_wrapper .options .option > a {
  width: 100%;
  height: 45px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  background: #175cff;
  color: white;
  font-size: 1.8rem;
  box-shadow: 10px 10px 20px #c7c7c7, -10px -10px 20px #f9f9f9;
  transition: .5s all linear;
}
.contact_options_section .main_wrapper .options .option > a:hover {
   background: #002c92;;
}
.contact_options_section .main_wrapper .options .option h3 {
  font-size: 2.2rem;
  font-family: "Open Sans", sans-serif;
  margin: 12px 0;
}
.contact_options_section .main_wrapper .options .option p {
  text-align: center;
  color: #575757;
  font-size: 1.6rem;
  font-family:  'Poppins', sans-serif;
  margin-bottom: 10px;
}
.contact_options_section .main_wrapper .options .option .link {
  font-size: 1.8rem;
  font-family:  'Poppins', sans-serif;

}

/* job cards */
.job_section {
  padding: 50px 0px;
}
.job_section .job_content_wrapper {
  width: 90%;
  margin: 0 auto;
}

.job_section .job_content_wrapper .job_content > h3 {
  font-size: 2rem;
  color: #175cff;
}

.job_section .job_content_wrapper .job_content > h2 {
  font-size: 3rem;
  color: #071c4d;
}

.job_section .job_content_wrapper .job_content .career_cards {
  padding-top: 10px;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(2, 1fr);
}

.job_section .job_content_wrapper .job_content .career_cards .card {
  background-color: #0b163f;
  padding: 20px 15px;
  border-radius: 5px;
}
.job_section .job_content_wrapper .job_content .career_cards .work {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.job_section .job_content_wrapper .job_content .career_cards .work .left {
  display: flex;
  align-items: center;
  gap: 20px;
}
.job_section .job_content_wrapper .job_content .career_cards .work .left h2 {
  color: rgba(255, 255, 255, 0.897);
}
.job_section
  .job_content_wrapper
  .job_content
  .career_cards
  .work
  .right
  > span {
  display: block;
  height: 35px;
  border-radius: 20px;
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d6d5e154;
  color: rgba(255, 255, 255, 0.897);
  font-size: 1.3rem;
}
.job_section .job_content_wrapper .job_content .career_cards .card .icon {
  margin-right: 5px;
  font-size: 2.8rem;
  color: rgba(255, 255, 255, 0.897);
}
.job_section .job_content_wrapper .job_content .career_cards .card .role h2 {
  font-size: 2rem;
  color: rgba(255, 255, 255, 0.897);
  padding-bottom: 10px;
}
.job_section .job_content_wrapper .job_content .career_cards .card .address {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.job_section
  .job_content_wrapper
  .job_content
  .career_cards
  .card
  .address
  span {
  display: inline-flex;

  align-items: center;
  justify-content: space-around;
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.897);
}

.job_section .job_content_wrapper .job_content .career_cards .card .button {
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  background-color: #0b163f;
  border: 1px solid rgba(255, 255, 255, 0.897);
  color: rgba(255, 255, 255, 0.897);
  cursor: pointer;
  transition: 0.5s all linear;
}

.job_section
  .job_content_wrapper
  .job_content
  .career_cards
  .card
  .button:hover {
  background-color: rgb(236, 218, 218);
  color: rgb(0, 0, 0);
  transform: translateY(-4px);
}

/* media querry */
@media (max-width: 1089px) {
  .job_section .job_content_wrapper .job_content .career_cards {
    grid-template-columns: 1fr;
  }

  .contact_options_section .main_wrapper .options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }
}
@media (max-width: 707px) {
  .contact_options_section .main_wrapper .options {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
}
