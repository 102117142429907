.team_wrapper {
  background-color: #fffbfb;
  padding-top: 40px;
}

.team_wrapper .content_cont {
  width: 90%;
  margin: 0 auto;
}

.team_wrapper .content_cont .title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}

.team_wrapper .content_cont .title h4 {
  font-size: 3.1rem;
  color: #071c4d;
}

.team_wrapper .content_cont .flex_wrapper {
  display: flex;
}

.team_wrapper .content_cont .flex_wrapper .left_faq_wrapper {
  width: 50%;
  /* background-color: red; */
}

.team_wrapper .content_cont .flex_wrapper .right_wrapper {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(0, 135, 142); */
}

.team_wrapper .content_cont .flex_wrapper .right_wrapper img {
  width: 78%;
  height: auto;
  /* background-color: rgb(0, 135, 142); */
}

.team_wrapper .content_cont .flex_wrapper .left_faq_wrapper .faq_item {
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  margin-bottom: 25px;
}

.team_wrapper .content_cont .flex_wrapper .left_faq_wrapper .faq_item .content {
  padding: 0 37px 0 0;
}

.team_wrapper .content_cont .flex_wrapper .left_faq_wrapper .faq_item .sequence {
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;

}

.team_wrapper .content_cont .flex_wrapper .left_faq_wrapper .faq_item .sequence img {
  width: 30%;
  height: auto;
}

.team_wrapper .content_cont .flex_wrapper .left_faq_wrapper .faq_item h2 span {
  font-size: 3.2rem;
  color: #175cff;
  margin-right: 5px;
  font-family: "Open Sans", sans-serif;
}

.team_wrapper .content_cont .flex_wrapper .left_faq_wrapper .faq_item h4 {
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
}

.team_wrapper .content_cont .flex_wrapper .left_faq_wrapper .faq_item p {
  font-size: 1.6rem;
  text-align: justify;
  font-family: sans-serif;
}

/* media querry */
@media (max-width: 859px) {
  .team_wrapper .content_cont .flex_wrapper .left_faq_wrapper {
    width: 100%;
  }

  .team_wrapper .content_cont .flex_wrapper {
    flex-direction: column;
  }

  .team_wrapper .content_cont .flex_wrapper .right_wrapper {
    width: 100%;
  }
}

@media (max-width: 778px) {
  .team_wrapper .content_cont .flex_wrapper .left_faq_wrapper .faq_item {
    margin-bottom: 4%;
  }
}

@media (max-width: 680px) {
  .team_wrapper .content_cont .title h2 {
    font-size: 2.5rem;
  }

  .team_wrapper .content_cont {
    width: 100%;
  }

  .team_wrapper .content_cont .flex_wrapper .left_faq_wrapper .faq_item {
    width: 100%;
  }
}

.faq_wrapper {
  background-color: #fffbfb;
  padding: 40px 40px;

}

.faq_wrapper .faq_container {
  width: 90%;
  margin: 0 auto;
}

.faq_wrapper .faq_container .faq_heading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}

.faq_wrapper .faq_container .faq_heading h4 {
  font-size: 3.1rem;
  color: #071c4d;
}

.faq_wrapper .faq_container .faq_item_container {
  display: flex;
  flex-direction: column;
}

.faq_wrapper .faq_container .faq_item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.faq_wrapper .faq_container .faq_item .icon {
  margin-right: 10px;
}

.faq_wrapper .faq_container .faq_item .icon img {
  width: 24px;
  height: 24px;
}

.faq_wrapper .faq_container .faq_item .content {
  cursor: pointer;
  width: 100%;
}

.faq_wrapper .faq_container .faq_item .content h4 {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  gap: 8px;
  box-shadow: 1px 1px 2px 1px #b9abab;
  padding: 15px 10px;
  border-radius: 8px;

}

.faq_wrapper .faq_container .faq_item .content p {
  font-size: 14px;
  padding-left: 10px;
  white-space: pre-line;
}

.faq_wrapper .faq_container .faq_item .content.openTitle {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.faq_wrapper .faq_container .faq_item .content.openContent {
  display: block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  width: 80%;
}

.faq_wrapper .faq_container .faq_item .content p {
  font-size: 16px;
}

.faq_wrapper .faq_container .faq_item .content ul {
  padding-left: 20px;
}

.faq_wrapper .faq_container .faq_item .content ul li {
  font-size: 15px;
  list-style-type: disc;

}

.faq_wrapper .faq_container .faq_item_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 40px;
  padding: 20px 10px;
}

.faq_wrapper .faq_container .column {
  display: flex;
  flex-direction: column;
  width: 50%;
}



@media (max-width:786px) {
  .faq_wrapper .faq_container .faq_item_container {
    display: flex;
    flex-direction: column;
  }

  .faq_wrapper .faq_container .column {
    width: 100%;
  }
}